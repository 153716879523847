<template>
  <div class="content">
    <template v-if="joiningData.aadhaar">
      <common-header
        :data="['home', 'joiningDocumentForm', 'editJoiningForm']"
        :componentName="'edit'"
        type="applicant"
      />
    </template>
    <template v-else>
      <common-header :data="['home', 'joiningDocumentForm']" type="applicant" />
    </template>
    <div class="container container-wrapper">
      <h3 class="text-center my-4">Personal Details</h3>
      <b-form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Email </label>
              <input
                type="text"
                name="email"
                key="email"
                v-validate="'required|email|max:100'"
                :class="{ invalid: errors.first('email') }"
                class="form-control"
                v-model="personalDetails.email"
                :disabled="true"
              />
              <span
                v-if="errors.first('email')"
                class="valid-feedback alert-danger"
                >{{ errors.first("email") }}</span
              >
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Secondary Email </label>
              <input
                type="text"
                name="secondary_email"
                key="secondary_email"
                v-validate="'required|email|max:100'"
                :class="{ invalid: errors.first('email') }"
                class="form-control"
                v-model="personalDetails.secondary_email"
              />
              <span
                v-if="errors.first('secondary_email')"
                class="valid-feedback alert-danger"
                >{{ errors.first("secondary_email") }}</span
              >
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Name as per Aadhaar </label>

              <input
                type="text"
                name="aadhaar_name"
                key="aadhaar_name"
                v-validate="'required|alpha_spaces|min:2|max:50'"
                :class="{ invalid: errors.first('aadhaar_name') }"
                class="form-control"
                v-model="personalDetails.nameAsPerAadhaar"
              />
              <span
                v-if="errors.first('aadhaar_name')"
                class="valid-feedback alert-danger"
                >{{ errors.first("aadhaar_name") }}</span
              >
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Mobile </label>
              <input
                type="text"
                name="mobile_number"
                key="mobile_number"
                v-validate="'required|numeric|length:10'"
                :class="{ invalid: errors.first('mobile_number') }"
                class="form-control"
                v-model="personalDetails.mobile_no"
              />
              <span
                v-if="errors.first('mobile_number')"
                class="valid-feedback alert-danger"
                >{{ errors.first("mobile_number") }}</span
              >
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">First Name </label>
              <input
                type="text"
                name="first_name"
                key="first_name"
                v-validate="'required|alpha_spaces|min:2|max:50'"
                :class="{ invalid: errors.first('first_name') }"
                class="form-control"
                v-model="personalDetails.first_name"
              />
              <span
                v-if="errors.first('first_name')"
                class="valid-feedback alert-danger"
                >{{ errors.first("first_name") }}</span
              >
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Last Name </label>

              <input
                type="text"
                name="last_name"
                key="last_name"
                placeholder="Enter Last Name"
                v-validate="'required|alpha_spaces|min:2|max:50'"
                :class="{ invalid: errors.first('last_name') }"
                class="form-control"
                v-model="personalDetails.last_name"
              />
              <span
                v-if="errors.first('last_name')"
                class="valid-feedback alert-danger"
                >{{ errors.first("last_name") }}</span
              >
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Mother's Name </label>

              <input
                type="text"
                name="mother_name"
                key="mother_name"
                v-validate="'required|alpha_spaces|min:2|max:50'"
                :class="{ invalid: errors.first('mother_name') }"
                class="form-control"
                v-model="personalDetails.motherName"
              />
              <span
                v-if="errors.first('mother_name')"
                class="valid-feedback alert-danger"
                >{{ errors.first("mother_name") }}</span
              >
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Father's Name </label>

              <input
                type="text"
                name="father_name"
                key="father_name"
                v-validate="'required|alpha_spaces|min:2|max:50'"
                :class="{ invalid: errors.first('father_name') }"
                class="form-control"
                v-model="personalDetails.fatherName"
              />
              <span
                v-if="errors.first('father_name')"
                class="valid-feedback alert-danger"
                >{{ errors.first("father_name") }}</span
              >
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Date of Birth </label>

              <input
                type="date"
                name="date_of_birth"
                key="date_of_birth"
                data-vv-as="DOB"
                v-validate="
                  `required|beforeDate:${new Date(
                    new Date().getFullYear() - 18,
                    new Date().getMonth(),
                    new Date().getDate()
                  )}`
                "
                :class="{ invalid: errors.first('date_of_birth') }"
                class="form-control"
                v-model="personalDetails.dob"
              />
              <span
                v-if="errors.first('date_of_birth')"
                class="valid-feedback alert-danger"
                style="top: 20px"
                >{{ errors.first("date_of_birth") }}</span
              >
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <FileInputField
              label="Passport Size Picture"
              :file="passportPhoto"
              :fileUrl="passportPhoto?.url"
              :file-extensions="['jpeg', 'png', 'jpg']"
              :required="true"
              @update:file="passportPhoto = $event"
              @clear-file="passportPhoto = null"
            />
          </div>
        </div>

        <h3 class="text-center my-4">Education Details</h3>

        <div class="row">
          <div class="col-sm-6">
            <FileInputField
              label="10th Certificate"
              :file="educationDetails.tenthCertificate"
              :fileUrl="educationDetails.tenthCertificate?.url"
              :file-extensions="['pdf']"
              :required="true"
              @update:file="educationDetails.tenthCertificate = $event"
              @clear-file="educationDetails.tenthCertificate = null"
            />
          </div>
          <div class="col-sm-6">
            <FileInputField
              label="12th Certificate"
              :file="educationDetails.twelvethCertificate"
              :fileUrl="educationDetails.twelvethCertificate?.url"
              :file-extensions="['pdf']"
              :required="true"
              @update:file="educationDetails.twelvethCertificate = $event"
              @clear-file="educationDetails.twelvethCertificate = null"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <FileInputField
              label="Degree"
              :file="educationDetails.degreeCertificate"
              :fileUrl="educationDetails.degreeCertificate?.url"
              :file-extensions="['pdf']"
              :required="true"
              @update:file="educationDetails.degreeCertificate = $event"
              @clear-file="educationDetails.degreeCertificate = null"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required"
                >Have you completed Post-Graduation?</label
              >
              <b-form-radio-group
                v-model="educationDetails.pgCompleted"
                :options="[
                  { text: 'Yes', value: true },
                  { text: 'No', value: false },
                ]"
                name="pgCompleted"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" v-if="educationDetails.pgCompleted">
            <FileInputField
              label="PG Certificate"
              :file="educationDetails?.pgDocuments"
              :fileUrl="educationDetails?.pgDocuments?.url"
              :file-extensions="['pdf']"
              :required="false"
              @update:file="educationDetails.pgDocuments = $event"
              @clear-file="educationDetails.pgDocuments = null"
            />
          </div>
        </div>
        <h3 class="text-center my-4">Work Experience</h3>

        <b-form-group>
          <label class="control-label required"
            >Do you have work experience?</label
          >
          <b-form-radio-group
            v-model="workExperience.hasExperience"
            name="hasExperience"
          >
            <b-form-radio :value="true">Yes</b-form-radio>
            <b-form-radio :value="false">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <div v-if="workExperience.hasExperience">
          <div class="row">
            <div class="col-sm-6">
              <FileInputField
                label="Experience Letter"
                :file="workExperience.experienceLetter"
                :fileUrl="workExperience.experienceLetter?.url"
                :file-extensions="['pdf']"
                :required="false"
                @update:file="workExperience.experienceLetter = $event"
                @clear-file="workExperience.experienceLetter = null"
              />
            </div>
            <div class="col-sm-6">
              <FileInputField
                label="Relieving Letter"
                :file="workExperience.relievingLetter"
                :fileUrl="workExperience.relievingLetter?.url"
                :file-extensions="['pdf']"
                :required="false"
                @update:file="workExperience.relievingLetter = $event"
                @clear-file="workExperience.relievingLetter = null"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <FileInputField
                label="Salary Slip"
                :file="workExperience.salarySlip"
                :fileUrl="workExperience.salarySlip?.url"
                :file-extensions="['pdf']"
                :required="false"
                @update:file="workExperience.salarySlip = $event"
                @clear-file="workExperience.salarySlip = null"
              />
            </div>
          </div>
        </div>

        <h3 class="text-center my-4">Identification Details</h3>

        <div class="row">
          <div class="col-sm-6">
            <FileInputField
              label="PAN Card"
              :file="identificationDetails.panCard"
              :fileUrl="identificationDetails.panCard?.url"
              :file-extensions="['pdf']"
              :required="true"
              @update:file="identificationDetails.panCard = $event"
              @clear-file="identificationDetails.panCard = null"
            />
          </div>

          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">PAN Number </label>

              <input
                type="text"
                name="pan_number"
                key="pan_number"
                v-validate="'required|alpha_num|length:10|pan_Number'"
                :class="{ invalid: errors.first('pan_number') }"
                class="form-control"
                @input="toUppercase"
                v-model="identificationDetails.panNumber"
              />
              <span
                v-if="errors.first('pan_number')"
                class="valid-feedback alert-danger"
                >{{ errors.first("pan_number") }}</span
              >
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <FileInputField
              label="Aadhaar Card"
              :file="identificationDetails.aadhaarCard"
              :fileUrl="identificationDetails.aadhaarCard?.url"
              :required="true"
              :file-extensions="['pdf']"
              @update:file="identificationDetails.aadhaarCard = $event"
              @clear-file="identificationDetails.aadhaarCard = null"
            />
          </div>
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required">Aadhaar Number </label>

              <input
                type="text"
                name="aadhaar_number"
                key="aadhaar_number"
                v-validate="'required|numeric|length:12'"
                :class="{ invalid: errors.first('aadhaar_number') }"
                class="form-control"
                v-model="identificationDetails.aadhaarNumber"
              />
              <span
                v-if="errors.first('aadhaar_number')"
                class="valid-feedback alert-danger"
                >{{ errors.first("aadhaar_number") }}</span
              >
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group>
              <label class="control-label required"
                >Do you have a UAN number?</label
              >
              <b-form-radio-group v-model="pfDetails.hasUan" name="hasUan">
                <b-form-radio :value="true">Yes</b-form-radio>
                <b-form-radio :value="false">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-sm-6" v-if="pfDetails.hasUan">
            <b-form-group>
              <label class="control-label required">UAN Number</label>
              <input
                type="text"
                name="uan_number"
                key="uan_number"
                v-validate="'required|numeric|length:12'"
                :class="{ invalid: errors.first('uan_number') }"
                class="form-control"
                v-model="pfDetails.uanNumber"
              />
              <span
                v-if="errors.first('uan_number')"
                class="valid-feedback alert-danger"
                >{{ errors.first("uan_number") }}</span
              >
            </b-form-group>
          </div>
        </div>
        <div class="text-center mb-2">
          <b-button class="btn btn-cp" type="submit">Submit</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileInputField from "./custom-file-input.vue";
import { Validator } from "vee-validate";
import { beforeDate } from "../../Common/_plugin/validation";

Validator.extend("pan_Number", {
  getMessage: () => "Invalid format, it should be like ABCDE1234F",
  validate: (value) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value),
});
Validator.extend("beforeDate", beforeDate);

export default {
  data() {
    return {
      actionType: "create",
      fetchedDocuments: [],
      personalDetails: {
        email: "",
        secondary_email: "",
        mobile_no: "",
        nameAsPerAadhaar: "",
        first_name: "",
        last_name: "",
        dob: "",
        motherName: "",
        fatherName: "",
      },
      educationDetails: {
        tenthCertificate: null,
        twelvethCertificate: null,
        degreeCertificate: null,
        pgCompleted: false,
        pgDocuments: null,
        tenthDocumentType: "tenthCertificate",
        twelvethDocumentType: "twelvethCertificate",
      },
      workExperience: {
        hasExperience: false,
        experienceLetter: null,
        relievingLetter: null,
        salarySlip: null,
      },
      identificationDetails: {
        panCard: null,
        panNumber: "",
        aadhaarCard: null,
        aadhaarNumber: "",
        panDocumentType: "panCard",
        aadhaarDocumentType: "aadhaarCard",
      },
      pfDetails: {
        hasUan: false,
        uanNumber: "",
      },
      passportPhoto: null,
    };
  },
  methods: {
    toUppercase() {
      this.identificationDetails.panNumber =
        this.identificationDetails.panNumber.toUpperCase();
    },
    appendObjectToFormData(formData, obj) {
      for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item) => formData.append(key, item));
          } else {
            formData.append(key, obj[key]);
          }
        }
      }
    },
    compareData(initialPersonalData, personalData) {
      const changedData = {};
      changedData.removedDocuments = {};

      for (const key in personalData) {
        if (
          initialPersonalData[key] &&
          personalData[key] !== initialPersonalData[key]
        ) {
          changedData[key] = personalData[key];
          if (
            typeof initialPersonalData[key] === "object" &&
            "url" in initialPersonalData[key]
          ) {
            changedData.removedDocuments[key] = initialPersonalData[key];
          }
        } else if (
          initialPersonalData.documents &&
          initialPersonalData.documents[key] &&
          personalData[key] !== initialPersonalData.documents[key]
        ) {
          changedData[key] = personalData[key];

          if (
            typeof initialPersonalData.documents[key] === "object" &&
            "url" in initialPersonalData.documents[key]
          ) {
            changedData.removedDocuments[key] =
              initialPersonalData.documents[key];
          }
        } else if (
          personalData[key] != null &&
          !initialPersonalData.documents[key] &&
          personalData[key] !== initialPersonalData[key]
        ) {
          changedData[key] = personalData[key];
        }
      }

      return changedData == {} ? null : changedData;
    },
    async submitForm() {
      await this.$validator.validateAll().then((valid) => {
        if (!valid) {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
          return; // Exit early if validation fails
        }
        const formData = new FormData();
        const action = this.actionType;
        const personalData = {
          ...this.personalDetails,
          ...this.educationDetails,
          ...this.workExperience,
          ...this.identificationDetails,
          ...this.pfDetails,
          passportPhoto: this.passportPhoto,
        };

        if (this.joiningData.aadhaarNumber) {
          let changedData = this.compareData(this.joiningData, personalData);
          let removedDocuments = {};
          if (!this.workExperience.hasExperience) {
            if (this.workExperience.experienceLetter) {
              removedDocuments.experienceLetter =
                this.workExperience.experienceLetter;
            }
            if (this.workExperience.relievingLetter) {
              removedDocuments.relievingLetter =
                this.workExperience.relievingLetter;
            }
            if (this.workExperience.salarySlip) {
              removedDocuments.salarySlip = this.workExperience.salarySlip;
            }
          }
          if (!this.educationDetails.pgCompleted) {
            if (this.educationDetails.pgDocuments) {
              removedDocuments.pgDocuments = this.educationDetails.pgDocuments;
            }
          }
          if (Object.keys(removedDocuments).length > 0) {
            changedData.removedDocuments = {
              ...changedData.removedDocuments,
              ...removedDocuments,
            };
          }
          if (Object.keys(changedData.removedDocuments).length) {
            changedData.removedDocuments = JSON.stringify(
              changedData.removedDocuments
            );
          } else changedData.removedDocuments = null;
          this.appendObjectToFormData(formData, changedData);
        } else {
          this.appendObjectToFormData(formData, personalData);
        }
        this.$store.dispatch("$_applicant_master/submitJoiningDocForm", {
          data: formData,
          type: action,
        });
      });
    },
  },
  components: {
    FileInputField,
  },
  watch: {
    joiningData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (newVal.aadhaarNumber) {
            this.actionType = "update";
          }
          this.personalDetails = {
            email: newVal.email || "",
            secondary_email: newVal.secondary_email || "",
            mobile_no: newVal.mobile_no || "",
            nameAsPerAadhaar: newVal.nameAsPerAadhaar || "",
            first_name: newVal.first_name || "",
            last_name: newVal.last_name || "",
            dob: newVal.dob || "",
            motherName: newVal.motherName || "",
            fatherName: newVal.fatherName || "",
          };

          this.educationDetails = {
            tenthCertificate: newVal.documents?.tenthCertificate || null,
            twelvethCertificate: newVal.documents?.twelvethCertificate || null,
            degreeCertificate: newVal.documents?.degreeCertificate || null,
            pgCompleted: newVal.pgCompleted || false,
            pgDocuments: newVal.documents?.pgDocuments || null,
          };
          this.workExperience = {
            hasExperience: newVal.hasExperience || false,
            experienceLetter: newVal.documents?.experienceLetter || null,
            relievingLetter: newVal.documents?.relievingLetter || null,
            salarySlip: newVal.documents?.salarySlip || null,
          };

          this.identificationDetails = {
            panCard: newVal.documents?.panCard || null,
            panNumber: newVal.panNumber || "",
            aadhaarCard: newVal.documents?.aadhaarCard || null,
            aadhaarNumber: newVal.aadhaarNumber || "",
          };

          this.pfDetails = {
            hasUan: newVal.hasUan,
            uanNumber: newVal.uanNumber || "",
          };
          this.passportPhoto = newVal.documents?.passportPhoto || null;
        }
      },
    },
  },
  created() {
    this.$store.dispatch("$_applicant_master/getJoiningData");
  },
  computed: {
    ...mapGetters({
      joiningData: "$_applicant_master/getJoiningData",
    }),
  },
};
</script>
