<template>
  <div>
    <b-form-group>
      <label class="control-label" :class="{ required: required }">{{
        label
      }}</label>
      <div v-if="fileUrl">
        <p v-if="file.type != 'passportPhoto'">
          File already uploaded:
          <a
            @click="viewFile()"
            style="cursor: pointer"
            class="text-primary"
            v-if="fileUrl"
          >
            View File
          </a>
        </p>
        <b-button class="btn btn-cp" @click="clearFile">Change File</b-button>
      </div>
      <b-form-file
        v-else
        v-model="localFile"
        @change="onFileChange"
        v-validate="{ required: required, isValiddocument: fileExtensions }"
        :data-vv-as="label"
        :name="label"
      />
      <span
        v-if="errors.first(label)"
        class="valid-feedback alert-danger"
        style="top: 20px"
        >{{ errors.first(label) }}</span
      >
    </b-form-group>
    <pdf-modal
      v-if="modalUrl"
      :modalId="modalTitle"
      :url.sync="modalUrl"
      :title="modalTitle"
      :axios="applicantAxios"
    ></pdf-modal>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { isValiddocument } from "../../Common/_plugin/validation";
Validator.extend("isValiddocument", isValiddocument);

import PdfModal from "../../Common/_components/pdf-modal.vue";
import applicantAxios from "../_utils/axios";
import config from "../../../config";
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    file: {
      required: false,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    fileExtensions: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localFile: null,
      config,
      applicantAxios,
      modalUrl: null,
      modalTitle: "",
    };
  },
  components: {
    "pdf-modal": PdfModal,
  },
  methods: {
    onFileChange() {
      if (this.localFile) {
        this.$emit("update:file", this.localFile); // Emit the file
      }
    },
    clearFile() {
      this.localFile = null;
      this.$emit("clear-file"); // Notify parent that file is cleared
    },
    viewFile() {
      this.modalTitle = this.file?.type;
      this.modalUrl = `${
        this.config.ROOT_API
      }/applicant-master/joining/documents/view/${
        this.file.type
      }?FileId=${encodeURIComponent(this.file?.url)}`;
      this.$nextTick(() => {
        $(`#${this.modalTitle}`).modal("show");
      });
    },
  },
  watch: {
    // Watch for changes to localFile and emit it if it has a value
    localFile(newFile) {
      if (newFile) {
        this.$emit("update:file", newFile);
      }
    },
  },
  inject: ["$validator"],
};
</script>
