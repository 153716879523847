var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"slotSelectTemplate","tabindex":"-1","role":"dialog","aria-labelledby":"myModalLabelSelectTemplate"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content m-t100"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('form',{staticClass:"content-form"},[(
              _vm.eventData.EventDetails.round_name == 'Interview' && _vm.pageLoaded
            )?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-12 control-label"},[_vm._v("Google Meet Invitations")]),_c('div',{staticClass:"col-sm-9"},[_c('label',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendInvitation),expression:"sendInvitation"}],staticClass:"mr-1",attrs:{"type":"radio","value":"common"},domProps:{"checked":_vm._q(_vm.sendInvitation,"common")},on:{"change":function($event){_vm.sendInvitation="common"}}}),_vm._v(" Send common invitation ")]),_c('label',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendInvitation),expression:"sendInvitation"}],staticClass:"mr-1",attrs:{"type":"radio","value":"individual"},domProps:{"checked":_vm._q(_vm.sendInvitation,"individual")},on:{"change":function($event){_vm.sendInvitation="individual"}}}),_vm._v(" Send individual invitations ")]),_c('label',{staticClass:"d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendInvitation),expression:"sendInvitation"}],staticClass:"mr-1",attrs:{"type":"radio","value":""},domProps:{"checked":_vm._q(_vm.sendInvitation,"")},on:{"change":function($event){_vm.sendInvitation=""}}}),_vm._v(" None(mail only) ")])])]):_vm._e(),(
              _vm.eventData.EventDetails.round_name == 'Interview' &&
              _vm.pageLoaded &&
              !_vm.sendInvitation
            )?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-12 control-label"},[_vm._v("Select Mail Template")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail_template),expression:"mail_template"}],staticClass:"custom-select main college mr-2",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.mail_template=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Select Mail Template")]),_vm._l((_vm.filteredMailTemplates),function(mailTemplate,index){return _c('option',{key:index,domProps:{"value":mailTemplate.id}},[_vm._v(" "+_vm._s(mailTemplate.name)+" ")])})],2)])]):_vm._e()])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-dark btn-sm",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$emit('canceled')}}},[_c('strong',[_vm._v("Cancel")])]),_c('button',{staticClass:"btn btn-cp ml-2",attrs:{"disabled":!_vm.mail_template && !_vm.sendInvitation,"type":"button"},on:{"click":_vm.submit}},[_c('i',{staticClass:"fa fa-book fa-lg",attrs:{"aria-hidden":"true"}}),_vm._v(" Submit ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title",attrs:{"id":"myModalLabelSelectTemplate"}},[_vm._v(" Select Template ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
}]

export { render, staticRenderFns }