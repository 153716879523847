<template>
  <div>
    <div class="file-card">
      <div class="file-card-header">
        <div class="file-icon">
          <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
        </div>
        <div class="file-title">{{ title }}</div>
        <div class="view-button">
          <b-icon
            v-if="id !== 'passportPhoto'"
            icon="eye"
            class="h5 m-2 view-icon"
            aria-hidden="true"
            @click="viewFile"
          ></b-icon>
          <b-icon
            icon="download"
            class="h5 m-2 view-icon"
            aria-hidden="true"
            @click="downloadFile"
          ></b-icon>
        </div>
      </div>
    </div>

    <pdf-modal
      v-if="modalUrl"
      :modalId="id"
      :url.sync="modalUrl"
      :title="modalTitle"
      :axios="selectedAxios"
    ></pdf-modal>
  </div>
</template>
<script>
import PdfModal from "../../Common/_components/pdf-modal.vue";
import applicantAxios from "../_utils/axios";
import config from "../../../config";
import axios from "axios";

export default {
  props: {
    title: String,
    id: String,
    documentUrl: String,
    isApplicant: Boolean,
  },
  data() {
    return {
      config,
      applicantAxios,
      modalUrl: null,
      modalTitle: "",
      axios,
    };
  },
  components: {
    "pdf-modal": PdfModal,
  },
  computed: {
    selectedAxios() {
      return this.isApplicant ? applicantAxios : axios;
    },
  },
  methods: {
    viewFile() {
      this.modalTitle = this.title;
      const basePath = this.isApplicant
        ? "/applicant-master/joining/documents/view/"
        : "/applicants/joining/documents/view/";

      this.modalUrl = `${this.config.ROOT_API}${basePath}${
        this.title
      }?FileId=${encodeURIComponent(this.documentUrl)}`;

      this.$nextTick(() => {
        $(`#${this.id}`).modal("show");
      });
    },
    async fetchDocumentAsBlob(url) {
      try {
        // const response = await axios.get(url, { responseType: "blob" });
        const response = this.isApplicant
          ? await applicantAxios.get(url, { responseType: "blob" })
          : await axios.get(url, { responseType: "blob" });
        const fileType = response.headers["content-type"];
        return {
          blob: new Blob([response.data], { type: fileType }),
          fileType,
        };
      } catch (error) {
        console.error("Error fetching document:", error);
        return null;
      }
    },

    async downloadFile() {
      try {
        // Construct the URL for the file
        const basePath = this.isApplicant
          ? "/applicant-master/joining/documents/view/"
          : "/applicants/joining/documents/view/";

        const fileUrl = `${this.config.ROOT_API}${basePath}${
          this.title
        }?FileId=${encodeURIComponent(this.documentUrl)}`;

        const { blob } = await this.fetchDocumentAsBlob(fileUrl);

        if (blob) {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          let extension = "";
          if (this.title === "passportPhoto") {
            extension = "jpg";
          } else {
            extension = "pdf";
          }

          // Use the title as the filename and include the appropriate extension
          link.setAttribute(
            "download",
            `${this.title}${extension ? "." + extension : ""}`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(link);
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Failed to download the document.",
          });
        }
      } catch (error) {
        console.error("Error in downloadFile:", error);
        this.$toast.error(".");
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "An error occurred while downloading the document.",
        });
      }
    },
  },
};
</script>

<style scoped>
@import "./tabStyles.css";
.file-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  max-width: 400px;
}

.file-card-header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
}

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef2f6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.file-title {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  flex-grow: 1;
}

.view-button {
  display: flex;
  align-items: center;
}

.view-icon {
  font-size: 1.2rem;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.view-icon:hover {
  color: #0056b3;
}
</style>
