<template>
  <div class="content" ref="tableParent">
    <common-header :data="['home', 'campusDrive', 'entityDetails']">
      <div
        class="d-flex"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
      >
        <div class="d-flex align-items-center mr-3">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              id="mailTypeSwitch"
              v-model="isBcc"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="mailTypeSwitch">
              <span style="color: black" v-if="!isBcc">CC</span>
              <span style="color: black" v-if="isBcc">BCC</span>
            </label>
          </div>
        </div>

        <div class="dropdown" style="width: auto">
          <button
            type="button"
            class="btn btn-cp mr-2 dropdown-toggle"
            :disabled="selectedRowCount === 0"
            @click="handleDropdownToggle"
            id="sendMailDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Send mail
          </button>
          <div class="dropdown-menu" aria-labelledby="sendMailDropdown">
            <a class="dropdown-item" @click="handleSendMailButton('primary')"
              >Primary</a
            >
            <a class="dropdown-item" @click="handleSendMailButton('secondary')"
              >Secondary</a
            >
            <a class="dropdown-item" @click="handleSendMailButton('both')"
              >Both</a
            >
          </div>
        </div>

        <button type="button" class="btn btn-cp">
          <router-link
            :to="{
              name: 'addEntityForm',
              params: { currentMode: 'addEntity' },
            }"
            id="link"
          >
            <i class="fa fa-plus" aria-hidden="true"></i> Add Entity
          </router-link>
        </button>
      </div>
    </common-header>
    <div class="container-fluid">
      <ag-grid-vue
        v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        :gridOptions="gridOptions"
        @gridReady="onGridReady"
        @selection-changed="onSelectionChanged"
        rowSelection="multiple"
        :columnDefs="columnDefs"
        :rowData="rowData"
      >
      </ag-grid-vue>
    </div>
    <component
      :is="selectedModal"
      @ready="showModal"
      @close="resetSelectedModal"
    ></component>

    <delete-entity-modal></delete-entity-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import EditEntityButton from "./edit-entity-button";
import deleteEntityModal from "./delete-entity-modal";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberof module:campusRecruitment
 * @namespace components.view_entity
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.view_entity
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      observer: null,
      gridOptions: null,
      columnDefs: null,
      rowData: null,
      selectedModal: "",
      selectedRowCount: 0,
      rowCountOnCurrentPage: -1,
      selectedEntities: [],
      isBcc: false,
      isDropdownOpen: false,
    };
  },
  components: {
    deleteEntityModal,
    "ag-grid-vue": AgGridVue,
    "entity-ratings": {
      template: `<div>
          <div class="d-inline-block" v-if="params.data.ratings" v-b-tooltip.hover :title="params.data.ratings">
            <span class="fa fa-star fa-lg mx-1 star-checked"
            v-for="(rating, index) in Array(starNumbers(params.data.ratings))" :key="index"></span>
          </div>
        </div>`,
      data() {
        return {
          ratingsArray: [
            { rating: 1, value: "poor" },
            { rating: 2, value: "moderate" },
            { rating: 3, value: "good" },
            { rating: 4, value: "very good" },
            { rating: 5, value: "excellent" },
          ],
        };
      },
      methods: {
        starNumbers(ratingString) {
          return this.ratingsArray.find(
            (rating) => rating.value == ratingString
          ).rating;
        },
      },
    },
    "entity-name": {
      template: `<div>
        <div v-if="params && params.data">
          <router-link :to="{name:'viewEntityDetails', params:{entityId: params.data.id}}">
            <div class="d-inline-block" style="color:#337ab7; font-size:13px;" :id="'entity-list-popover-target-1'+params.rowIndex">
              {{params.data.entity_name}}
              </div>
              <b-popover :target="'entity-list-popover-target-1'+params.rowIndex" triggers="hover" placement="right">
                Click here to see entity details
              </b-popover>
          </router-link>
        </div>
      </div>`,
    },
    "contact-person-name": {
      template: `<div>
                    <div v-if ="params.data.contactPersonDetails.length != 0" v-for="(contact,index) in params.data.contactPersonDetails"
                    :style="params.data.contactPersonDetails[index].isPrimary?'fontWeight:bold':''">
                      {{contact.name}}
                    </div>
                    <div v-else>
                       Not Available
                    </div>
                </div>`,
    },
    "contact-person-email": {
      template: `<div>
                    <div v-if ="params.data.contactPersonDetails.length != 0" v-for="(contact,index) in params.data.contactPersonDetails"
                    :style="params.data.contactPersonDetails[index].isPrimary?'fontWeight:bold':''">
                      {{contact.email}}  {{contact.alternate_email}}
                    </div>
                </div>`,
    },
    "contact-person-mobile_no": {
      template: `<div>
                    <div v-if ="params.data.contactPersonDetails" v-for="(contact,index) in params.data.contactPersonDetails"
                    :style="params.data.contactPersonDetails[index].isPrimary?'fontWeight:bold':''">
                      {{contact.mobile_no}}&nbsp;&nbsp;&nbsp;{{contact.alternate_mobile_no}}
                    </div>
                </div>`,
    },
    "course-name": {
      template: `<div>
                    <div v-if ="params.data.courseDetails.length != 0" >
                      <div v-for="(course,index) in params.data.courseDetails">
                        {{course.course_name}}
                      </div>
                    </div>
                    <div v-else>
                       Not Available
                    </div>
                 </div>`,
    },
    "course-strength": {
      template: `<div>
                    <div v-if ="params.data.courseDetails.length != 0" >
                      <div v-for="(course,index) in params.data.courseDetails">
                        {{course.course_strength}}
                      </div>
                    </div>
                    <div v-else>
                       Not Available
                    </div>
                 </div>`,
    },

    "edit-entity-button": EditEntityButton,
  },

  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].ENTITY;
    },
    ...mapGetters({
      entities: "$_campus_recruitment/entities",
    }),
    mailType() {
      return this.isBcc ? "mail_bcc" : "mail_cc";
    },
  },
  watch: {
    entities(value) {
      this.rowData = value;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },

    onSelectionChanged() {
      this.selectedEntities = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedEntities.length;
    },
    /**
     * @memberof module:campusRecruitment.components.view_entity
     * @param {String} identifier
     * @description Open modal
     */
    showModal(identifier) {
      $(identifier).modal("show");
    },
    onGridReady(params) {
      if (params.api) {
        this.gridOptions.api = params.api;
      }
    },
    handleSendMailButton(contactType) {
      const type = this.mailType;
      const data = [...this.selectedEntities, { type, contactType }];
      setTimeout(() => {
        this.$store.dispatch("$_mailbox/setEntitiesData", {
          data: data,
        });
      }, 300);
      this.$router.push({
        name: "mailbox",
        query: {
          key: "MailTo",
          redirect: this.$route.fullPath,
        },
      });
    },
    handleDropdownToggle() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    /**
     * @memberof module:campusRecruitment.components.view_entity
     * @description Reset modal
     */
    resetSelectedModal() {
      this.selectedModal = "";
    },
    formatContacts(data) {
      let activeContacts = data.filter((contact) => contact.status == "Active");
      let primaryContact = data.find((contact) => contact.isPrimary);

      if (primaryContact) {
        activeContacts = activeContacts.filter((contact) => !contact.isPrimary);
        activeContacts.unshift(primaryContact);
      }
      return activeContacts;
    },

    /**
     * @memberof module:campusRecruitment.components.view_entity
     * @description Create columns for grid
     */
    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: "Entity Name",
          field: "entity_name",
          cellRenderer: "entity-name",
          sortable: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          comparator: (valueA, valueB) => {
            if (valueA && valueB) {
              return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            }
            return 0;
          },

          width: 300,
          minWidth: 300,
        },
        {
          headerName: "Ratings",
          field: "ratings",
          sortable: true,
          width: 150,
          minWidth: 150,
          comparator: function (valueA, valueB) {
            const order = [
              "poor",
              "moderate",
              "good",
              "very good",
              "excellent",
            ];
            const indexA = order.indexOf(valueA);
            const indexB = order.indexOf(valueB);
            return indexA - indexB;
          },
          cellRenderer: "entity-ratings",
        },
        {
          headerName: "Contact Person Details",
          children: [
            {
              headerName: "Name",
              cellRenderer: "contact-person-name",
              suppressFilter: true,
              valueGetter: (params) =>
                params.data && params.data.contactPersonDetails
                  ? params.data.contactPersonDetails
                      .map((contact) => contact.name)
                      .join(", ")
                  : "",
              filter: true,
              minWidth: 100,
            },
            {
              headerName: "Email",
              cellRenderer: "contact-person-email",
              valueGetter: (params) =>
                params.data && params.data.contactPersonDetails
                  ? params.data.contactPersonDetails
                      .map((contact) => contact.email)
                      .join(", ")
                  : "",
              suppressFilter: true,
              filter: true,
              minWidth: 100,
            },
            {
              headerName: "Contact",
              cellRenderer: "contact-person-mobile_no",
              valueGetter: (params) =>
                params.data && params.data.contactPersonDetails
                  ? params.data.contactPersonDetails
                      .map((contact) => contact.mobile_no)
                      .join(", ")
                  : "",
              suppressFilter: true,
              filter: true,
              minWidth: 100,
            },
          ],
          suppressFilter: true,
          filter: false,
        },
        {
          headerName: "Course Details",
          children: [
            {
              headerName: "Course Name",
              cellRenderer: "course-name",
              suppressFilter: true,
              filter: true,
              minWidth: 100,
              valueGetter: (params) =>
                params.data && params.data.courseDetails
                  ? params.data.courseDetails
                      .map((course) => course.course_name)
                      .join(", ")
                  : "",
            },
            {
              headerName: "Course Strength",
              cellRenderer: "course-strength",
              suppressFilter: true,
              filter: true,
              minWidth: 100,
              valueGetter: (params) =>
                params.data && params.data.courseDetails
                  ? params.data.courseDetails
                      .map((course) => course.course_strength)
                      .join(", ")
                  : "",
            },
          ],
          suppressFilter: true,
          filter: false,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          width: 100,
          minWidth: 100,
        },
        {
          headerName: "State",
          field: "state",
          sortable: true,
          width: 150,
          minWidth: 150,
        },
        {
          headerName: "Entity Type",
          field: "entity_type",
          sortable: true,
          width: 100,
          minWidth: 100,
        },
        {
          headerName: "Action",
          cellRenderer: "edit-entity-button",
          suppressFilter: true,
          filter: false,
          width: 80,
          minWidth: 80,
          pinned: "right",
        },
      ];
    },

    configGridOptions() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      let componentThis = this;

      this.gridOptions = {
        headerHeight: 30,
        suppressPropertyNamesCheck: true,
        enableCellTextSelection: true,
        pagination: true,
        paginationPageSizeSelector: false,
        enableColResize: true,
        // rowHeight: 50,
        defaultColDef: {
          flex: 1,
          floatingFilter: true,
          resizable: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          // set filtering on for all columns
          filter: true,
        },
        context: {
          componentParent: this,
        },
        getRowHeight: function (params) {
          params.data.contactPersonDetails = componentThis.formatContacts(
            params.data.contactPersonDetails
          );
          let height = Math.max(
            params.data.contactPersonDetails.length,
            params.data.courseDetails.length
          );
          if (height != 0) {
            return 25 * height;
          }
          return 25;
        },
      };
      this.createColumnDefs();
    },
  },

  /**
   * @memberof module:campusRecruitment.components.view_entity
   * @description lifecycle method - set all parameter for grid like gow height, filter etc.
   */
  beforeMount() {
    this.configGridOptions();
  },

  /**
   * @memberof module:campusRecruitment.components.view_entity
   * @description lifecycle method - fetch all entity data
   */
  created() {
    this.$store.dispatch("$_campus_recruitment/fetchEntities");
  },
};
</script>

<style scoped>
.star-checked {
  color: orange;
}
#link {
  color: white;
}

.ag-grid-style {
  height: calc(100vh - 135px);
  min-height: 500px;
}
/* Scoped deep selector to override AG Grid styles */
:deep(.ag-theme-alpine .ag-cell),
:deep(.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group) {
  --ag-line-height: 25px !important;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: black;
}
.custom-switch .custom-control-label::before {
  border-color: black;
}
custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #903564 !important;
  background-color: #903564 !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #903564;
  background-color: #903564;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.dropdown-menu {
  min-width: 7rem;
}

.dropdown-item {
  padding: 0.25rem 1rem !important;
}

/* MEDIA QUERY FOR SMALL SCREEN */
@media screen and (max-width: 395px) {
  .ag-grid-style {
    min-height: 400px;
    height: calc(100vh - 180px);
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 287px) {
  .ag-grid-style {
    height: calc(100vh - 205px);
    min-height: 100px;
  }
}
</style>
