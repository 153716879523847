<template>
  <div class="w-100 pl-md-2" style="min-height: 100%">
    <div v-if="!selectedSlotId" class="text-center">
      Select a slot to view slot data
    </div>
    <drop v-else class="drop" @drop="handleDrop">
      <div
        class="pb-2"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex">
            <div class="btn-group dropup">
              <button
                class="btn dropdown-toggle btn-sm btn-cp ml-0 my-1"
                type="button"
                data-toggle="dropdown"
                id="dropdownMenuButtonli"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-tasks" aria-hidden="true"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonli">
                <li class="dropdown-header">Manage</li>
                <li class="dropdown-item" @click="selectAllOnCurrentPage">
                  Select all
                </li>
                <li class="dropdown-item" @click="deselectAllOnCurrentPage">
                  Deselect all
                </li>
                <li
                  class="dropdown-item"
                  @click="passAllSelectedApplicant('passed')"
                >
                  Pass selected
                </li>
                <li
                  class="dropdown-item"
                  @click="failAllSelectedApplicants('failed')"
                >
                  Fail selected
                </li>
                <li
                  class="dropdown-item"
                  @click="downloadData()"
                  v-if="eventData.EventDetails.round_name === 'Written Test'"
                >
                  Download data
                </li>
                <li
                  class="dropdown-item"
                  @click="openRemoveApplicantModal"
                  v-if="
                    hasAccess({
                      permissions: [BASE_PERMISSION['SCHEDULE-CREATE']],
                    })
                  "
                >
                  Remove selected applicants from slot
                </li>
                <li
                  class="dropdown-item"
                  @click="openPassQualified"
                  v-if="
                    eventData.EventDetails.round_name === 'Written Test' &&
                    hasAccess({
                      permissions: [BASE_PERMISSION['SCHEDULE-UPDATE']],
                    })
                  "
                >
                  Pass Qualified
                </li>
                <li
                  class="dropdown-item"
                  @click="openUnenrollModal"
                  v-if="
                    eventData.EventDetails.round_name === 'Written Test' &&
                    hasAccess({
                      permissions: [
                        BASE_PERMISSION['SCHEDULE-CREATE'],
                        BASE_PERMISSION['SCHEDULE-UPDATE'],
                      ],
                      checkAll: false,
                    })
                  "
                >
                  Unenroll Applicants
                </li>
                <li
                  class="dropdown-item"
                  @click="openUnenrollDeleteModal"
                  v-if="
                    eventData.EventDetails.round_name === 'Written Test' &&
                    hasAccess({
                      permissions: [
                        BASE_PERMISSION['SCHEDULE-CREATE'],
                        BASE_PERMISSION['SCHEDULE-UPDATE'],
                      ],
                      checkAll: false,
                    })
                  "
                >
                  Unenroll and Delete Applicants
                </li>
                <li class="dropdown-header">Mail</li>
                <li
                  class="dropdown-item"
                  @click="sendGoogleMeet"
                  v-if="
                    eventData.EventDetails.round_name === 'Interview' &&
                    hasAccess({
                      permissions: [
                        BASE_PERMISSION['SCHEDULE-CREATE'],
                        BASE_PERMISSION['SCHEDULE-UPDATE'],
                      ],
                      checkAll: false,
                    })
                  "
                >
                  Send Google Meet
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex ml-auto flex-wrap justify-content-end">
            <button
              v-if="
                this.receivedApplicants.length > 0 &&
                hasAccess({ permissions: [BASE_PERMISSION['SCHEDULE-CREATE']] })
              "
              @click="handleUpload"
              class="btn btn-sm btn-cp text-nowrap my-1 ml-auto"
            >
              Submit
            </button>
            <div
              class="alert alert-info alert-xs ml-2 my-1"
              v-if="selectedRowCount > 0"
            >
              {{ selectedRowCount }}
            </div>
            <button
              class="btn btn-sm btn-cp my-1 ml-2"
              v-if="totalSelectable > 0 && selectedApplicants.length == 0"
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp btn-sm my-1 ml-2"
              v-else-if="
                totalSelectable > 0 &&
                selectedApplicants.length > 0 &&
                selectedApplicants.length < totalSelectable
              "
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-minus-square" aria-hidden="true"></i>Select all
            </button>
            <button
              class="btn btn-sm btn-cp my-1 ml-2"
              v-else-if="totalSelectable > 0"
              @click="deselectAllOnCurrentPage"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i> Deselect all
            </button>
            <div class="ml-2 my-1">
              <select
                @change="selectFilterApplicant($event)"
                class="form-control form-control-sm select-cp"
              >
                <option
                  class="pagesizeoption-slot-filter"
                  value="all"
                  selected=""
                >
                  All
                </option>
                <option class="pagesizeoption-slot-filter" value="not uploaded">
                  Not uploaded
                </option>
                <option class="pagesizeoption-slot-filter" value="scheduled">
                  Scheduled
                </option>
                <option class="pagesizeoption-slot-filter" value="passed">
                  Passed
                </option>
                <option class="pagesizeoption-slot-filter" value="failed">
                  Failed
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })">
          <ag-grid-vue
            class="ag-theme-alpine ag-grid-style"
            v-if="gridOptions"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :getRowStyle="getRowStyle"
            :enableBrowserTooltips="true"
            :gridOptions="gridOptions"
            @gridReady="onGridReady"
          >
          </ag-grid-vue>
        </div>
      </div>
    </drop>
    <pass-qualified-modal
      v-if="eventData.EventDetails.round_name === 'Written Test'"
      :pendingApplicantIds="pendingApplicantIds"
      :selectedSlotData="selectedSlotData"
      :eventId="eventId"
      @submitted="onPassQualified"
    ></pass-qualified-modal>
    <template
      v-if="hasAccess({ permissions: [BASE_PERMISSION['SCHEDULE-CREATE']] })"
    >
      <select-moodle-course-modal
        v-if="eventData.EventDetails.round_name === 'Written Test'"
        :inPlaceAction="true"
        @setSelctedMailTemplate="setSelctedMailTemplate"
        @selected="addApplicantsToSlot"
      ></select-moodle-course-modal>
      <cancel-round-modal
        :selectedSlotData="selectedSlotData"
        @setSelctedMailTemplateCancelRound="setSelctedMailTemplateCancelRound"
        :inPlaceAction="true"
      ></cancel-round-modal>
      <select-template-modal
        @setSelctedMailTemplate="setSelctedMailTemplate"
        v-if="eventData.EventDetails.round_name === 'Interview'"
        :inPlaceAction="true"
        @selected="addApplicantsToSlot"
      ></select-template-modal>
    </template>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <add-score-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION['SCHEDULE-UPDATE']] })"
      ref="addScoreSlot"
      :roundData="roundData"
      :selectedSlotData="selectedSlotData"
      :interviewData="interviewData"
      :setIterviewData="setIterviewData"
      :refreshSlotPage="refreshSlotPage"
    ></add-score-modal>
    <add-multiple-score-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION['SCHEDULE-UPDATE']] })"
      ref="addMultipleScoreSlot"
      :roundData="roundData"
      :selectedSlotData="selectedSlotData"
      :interviewData="interviewData"
      :setIterviewData="setIterviewData"
      :refreshSlotPage="refreshSlotPage"
    ></add-multiple-score-modal>

    <!-- Unenroll Users Confirmation Modal -->
    <confirmation-modal
      ref="confirmUnenrollModal"
      modal-id="confirm-unenroll-modal"
      modal-title="Confirm Unenroll"
      modal-message="Are you sure you want to unenroll all applicants?"
      :action="unenrollUsers"
    />
    <!-- Unenroll and Delete Users Confirmation Modal -->
    <confirmation-modal
      ref="confirmUnenrollDeleteModal"
      modal-id="confirm-unenroll-delete-modal"
      modal-title="Confirm Unenroll and Delete"
      modal-message="Are you sure you want to unenroll and delete all applicants?"
      :action="unenrollDeleteUsers"
    />
    <!-- Remove Selected Applicant Confirmation Modal -->
    <confirmation-modal
      ref="confirmRemoveApplicantModal"
      modal-id="confirm-remove-applicant-modal"
      modal-title="Confirm Remove Applicant"
      modal-message="Are you sure you want
    to remove the selected applicants from this slot?"
      :action="removeSelectedFromSlot"
    />
  </div>
</template>

<script>
import api from "../_api/index";
import { mapGetters } from "vuex";
import { Drop } from "vue-drag-drop";
import { AgGridVue } from "ag-grid-vue";
import { COMMON_DATA_COL_DEFS } from "../_utils/event_slot_applicant_cols";
import slotApplicantActionCellVue from "./slot-applicant-action-cell.vue";
import moment from "moment";
import SelectMoodleCourseModal from "./select-moodle-course-modal.vue";
import SelectTemplateModal from "./select-template-modal.vue";
import PassQualifiedModal from "./pass-qualified-modal.vue";
import CommonModal from "../../Common/_components/modal";
import confirmationModal from "../../Common/_components/confirmation-modal.vue";
import addScoreModal from "./add-score-modal.vue";
import addMultipleScoreModal from "./add-multiple-score-modal.vue";
import CancelRoundModal from "./cancel-round-modal.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";
export default {
  props: [
    "selectedSlotId",
    "setDataSource",
    "refreshGrid",
    "slotsList",
    "fetchEventSlots",
  ],
  data() {
    return {
      eventId: parseInt(this.$route.params.eventId),
      interviewData: null,
      filterSelected: "all",
      selectedSlotData: null,
      receivedApplicants: [],
      instanceCourses: {},
      gridOptions: null,
      columnDefs: COMMON_DATA_COL_DEFS,
      loadingTemplate: "No applicants found",
      noRowsTemplate: "No applicants found",
      selectedApplicants: [],
      selectedRowCount: null,
      dropData: null,
      roundData: null,
      roundDataAction: null,
      selectedCancelApplicant: null,
      cancelRoundMailTemplateId: null,
      cancelSingleApplicant: null,
      maxApplicantCountToCurrentPage: null,
      totalSelectable: null,
    };
  },
  components: {
    SelectMoodleCourseModal,
    confirmationModal,
    "ag-grid-vue": AgGridVue,
    Drop,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.slug } }"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "slot-applicant-action": slotApplicantActionCellVue,
    PassQualifiedModal,
    CommonModal,
    SelectTemplateModal,
    addScoreModal,
    CancelRoundModal,
    addMultipleScoreModal,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].SLOT;
    },
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
    }),
    pendingApplicantIds() {
      if (!this.selectedSlotData) return [];
      const filterApplicants = this.selectedSlotData.applicants.filter(
        (applicant) => {
          return (
            applicant.roundData &&
            applicant.roundData[0].round_result == "Pending"
          );
        }
      );
      return filterApplicants.map((applicant) => applicant.id);
    },
  },
  watch: {
    selectedSlotId(value) {
      this.receivedApplicants = [];
      this.instanceCourses = {};
      this.selectedSlotData = null;
      this.selectedRowCount = null;
      if (value) {
        this.getSlotData(value);
      } else {
        this.selectedSlotData = null;
      }
    },
    dropData() {},
    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description filter slot applicants based on status selected
     */
    filterSelected() {
      this.gridOptions.api.setGridOption(
        "rowData",
        this.filterApplicants([
          ...this.receivedApplicants,
          ...this.selectedSlotData.applicants,
        ])
      );
    },
  },
  methods: {
    downloadData() {
      let columns = this.gridOptions.api.getAllGridColumns();
      columns = columns.filter(function (col) {
        return !col.colDef.display_only;
      });
      let excel_param = {
        columnKeys: columns,
        fileName: "Applicant's Data.csv",
      };
      if (this.selectedApplicants.length > 0) {
        excel_param.onlySelected = true;
      }
      this.gridOptions.api.exportDataAsCsv(excel_param);
    },
    openUnenrollModal() {
      this.$refs.confirmUnenrollModal.$refs.confirmationModal.show();
    },
    openUnenrollDeleteModal() {
      this.$refs.confirmUnenrollDeleteModal.$refs.confirmationModal.show();
    },
    openRemoveApplicantModal() {
      this.$refs.confirmRemoveApplicantModal.$refs.confirmationModal.show();
    },
    unenrollUsers() {
      const data = [];
      let instanceId;
      if (this.selectedApplicants.length > 0) {
        this.selectedApplicants.forEach((r) => {
          instanceId =
            r.roundData[0]?.hasMoodle?.moodle_user_master?.moodle_instance_id;
          if (instanceId) {
            data.push({
              applicantId: r.id,
              instanceId,
              roundId: r.roundData[0]?.id,
            });
          }
        });
      } else {
        this.gridOptions.api.forEachNode((r) => {
          instanceId =
            r.data.roundData[0]?.hasMoodle?.moodle_user_master
              ?.moodle_instance_id;
          if (instanceId) {
            data.push({
              applicantId: r.data.id,
              instanceId,
              roundId: r.data.roundData[0]?.id,
            });
          }
        });
      }
      api
        .unenrolMoodleUserFromCourse(this.eventData.EventDetails.id, data)
        .then(() => {
          this.refreshSlotPage(); // Refresh the slot page after unenroll
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Users unenrolled",
            },
            { root: true }
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Something went wrong",
            },
            { root: true }
          );
        });
    },
    unenrollDeleteUsers() {
      const data = [];
      let instanceId;
      if (this.selectedApplicants.length > 0) {
        this.selectedApplicants.forEach((r) => {
          instanceId =
            r.roundData[0]?.hasMoodle?.moodle_user_master?.moodle_instance_id;
          if (instanceId) {
            data.push({ applicantId: r.id, instanceId });
          }
        });
      } else {
        this.gridOptions.api.forEachNode((r) => {
          instanceId =
            r.data.roundData[0]?.hasMoodle?.moodle_user_master
              ?.moodle_instance_id;
          if (instanceId) {
            data.push({ applicantId: r.data.id, instanceId });
          }
        });
      }
      api
        .unenrolDeleteUserFromMoodle(data)
        .then(() => {
          this.refreshSlotPage(); // Refresh the slot page after deletion
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Users deleted from moodle successfully",
            },
            { root: true }
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Something went wrong",
            },
            { root: true }
          );
        });
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    removeSelectedFromSlotSelecteMailTemplate() {
      if (this.isAtleastOneSelected()) {
        if (this.eventData.EventDetails.round_name == "Written Test") {
          const uploaded = this.selectedApplicants.filter((applicant) => {
            return !applicant.not_uploaded;
          });
          this.cancelSingleApplicant = false;
          if (uploaded.length > 0) {
            $("#cancelRoundModal").modal("show");
          } else {
            this.removeSelectedFromSlot();
          }
        } else {
          this.removeSelectedFromSlot();
        }
      }
    },

    setSelctedMailTemplateCancelRound(templateId) {
      this.cancelRoundMailTemplateId = templateId;
      if (this.cancelSingleApplicant != null) {
        this.cancelSingleApplicant
          ? this.cancelApplicantRound()
          : this.removeSelectedFromSlot();
      }
    },
    setSelctedMailTemplate(selctedMailId) {
      this.dropData = this.dropData.map((applicant) => {
        return { ...applicant, mail_template: selctedMailId };
      });
    },
    refreshSlotPage() {
      this.fetchEventSlots();
      this.receivedApplicants = [];
      this.instanceCourses = {};
      this.getSlotData(this.selectedSlotId);
      this.refreshGrid();
    },

    setIterviewData(data) {
      this.interviewData = data;
    },
    /**
     * @memberof module:CampusRecruitment.slot-page
     * @returns {Boolean}
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    isAtleastOneSelected() {
      if (this.selectedApplicants.length > 0) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @returns {Boolean}
     * @description Check whether every applicants status is scheduled
     */
    selectedApplicantsValid(regex) {
      let applicantValid = true;
      this.selectedApplicants.every((applicant) => {
        if (regex.test(applicant.application_status)) {
          return true;
        } else {
          applicantValid = false;
          return false;
        }
      });
      if (applicantValid) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select only scheduled applicants",
          false,
          "danger",
          "modal-m"
        );
        return false;
      }
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description pass applicants round
     */
    passApplicantRound(applicant) {
      if (applicant.roundData) {
        let filterRound = applicant.roundData.filter((round) => {
          return round.round_result == "Pending";
        });
        if (filterRound.length == 1) {
          if (
            filterRound[0].round_name == this.eventData.EventDetails.round_name
          ) {
            this.roundData = filterRound[0];
            this.roundDataAction = "Passed";
            this.$refs.addScoreSlot.setPassRoundModal(true);
            $("#addScoreSlot").modal("show");
          } else {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Event and round have different round type.",
              },
              { root: true }
            );
          }
        } else if (filterRound.length > 1) {
          this.roundData = filterRound[0];
          this.roundDataAction = "Passed";
          this.$refs.addScoreSlot.setPassRoundModal(true);
          $("#addScoreSlot").modal("show");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Multiple round is active for this applicant",
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "No active round found",
            },
            { root: true }
          );
        }
      }
    },
    passSelectedApplicantRound(applicant) {
      if (applicant.roundData) {
        let filterRound = applicant.roundData.filter((round) => {
          return round.round_result === "Pending";
        });

        if (filterRound.length === 1) {
          if (
            filterRound[0].round_name === this.eventData.EventDetails.round_name
          ) {
            return true;
          } else {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Event and round have different round types.",
              },
              { root: true }
            );
          }
        } else if (filterRound.length > 1) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Multiple rounds are active for this applicant",
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "No active round found",
            },
            { root: true }
          );
        }
      }
      return false;
    },
    showAddScoreModal(validApplicants, action) {
      this.roundDataAction = action;
      this.roundData = validApplicants;
      this.$refs.addMultipleScoreSlot.setPassRoundModal(action === "passed");
      $("#addMultipleScoreSlot").modal("show");
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description fail applicants round
     */
    failApplicantRound(applicant) {
      if (applicant.roundData) {
        let filterRound = applicant.roundData.filter((round) => {
          return round.round_result == "Pending";
        });
        if (filterRound.length == 1) {
          if (
            filterRound[0].round_name == this.eventData.EventDetails.round_name
          ) {
            this.roundData = filterRound[0];
            this.roundDataAction = "Failed";
            this.$refs.addScoreSlot.setPassRoundModal(false);
            $("#addScoreSlot").modal("show");
          } else {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Applicant have different round type.",
              },
              { root: true }
            );
          }
        } else if (filterRound.length > 1) {
          this.roundData = filterRound[0];
          this.roundDataAction = "Failed";
          $("#addScoreSlot").modal("show");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Multiple round is active for this applicant",
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "No active round found",
            },
            { root: true }
          );
        }
      }
    },
    failSelectedApplicantRound(applicant) {
      if (applicant.roundData) {
        let filterRound = applicant.roundData.filter((round) => {
          return round.round_result === "Pending";
        });

        if (filterRound.length === 1) {
          if (
            filterRound[0].round_name === this.eventData.EventDetails.round_name
          ) {
            return true;
          } else {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Event and round have different round types.",
              },
              { root: true }
            );
          }
        } else if (filterRound.length > 1) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Multiple rounds are active for this applicant",
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "No active round found",
            },
            { root: true }
          );
        }
      }
      return false;
    },
    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description cancel applicants round
     */
    cancelApplicantRound() {
      const applicant = this.selectedCancelApplicant;
      let cancelApplicantData = [];

      let instanceId =
        applicant.roundData[0]?.hasMoodle?.moodle_user_master
          ?.moodle_instance_id;
      cancelApplicantData.push({
        applicantId: applicant.id,
        instanceId,
        roundId: applicant.roundData[0]?.id,
      });

      const data = {
        id: this.selectedSlotId,
        applicantsId: cancelApplicantData,
        mail_template: this.cancelRoundMailTemplateId,
        eventId: this.eventData.EventDetails.id,
      };
      api
        .removeApplicantsFromSlot(data)
        .then(() => {
          this.fetchEventSlots();
          this.receivedApplicants = [];
          this.instanceCourses = {};
          this.getSlotData(this.selectedSlotId);
          this.refreshGrid();
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Applicant's round has been cancelled.",
            },
            { root: true }
          );
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: err.response.data.message
                ? err.response.data.message
                : "Something went wrong",
            },
            { root: true }
          );
        });
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description select template to cancel round
     */
    cancelApplicantRoundSelectMailTemplate(applicant) {
      this.selectedCancelApplicant = applicant;
      if (this.eventData.EventDetails.round_name == "Written Test") {
        this.cancelSingleApplicant = true;
        $("#cancelRoundModal").modal("show");
      } else {
        this.cancelApplicantRound();
      }
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description check if event is valid
     */
    scheduleDateInvalid(date) {
      return moment(date).isBefore(new Date());
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description get slot data by slot id
     */
    getSlotData(value) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      api
        .getSelectedSlotData(value)
        .then((res) => {
          this.gridOptions.api.paginationGoToFirstPage();
          this.selectedSlotData = res.data;
          this.gridOptions.api.setGridOption(
            "rowData",
            this.filterApplicants([
              ...this.receivedApplicants,
              ...res.data.applicants,
            ])
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description format date
     */
    formatDate(date) {
      return moment(date).format("MMM Do YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description extract date
     */
    extractDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description extract time
     */
    extractTime(date) {
      return moment(date).format("HH:mm");
    },

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description handle drop applicants event
     */
    handleDrop(data) {
      if (data) {
        this.dropData = data;
        if (this.eventData.EventDetails.round_name === "Written Test") {
          $("#selectCourse").modal("show");
        } else {
          $("#slotSelectTemplate").modal("show");
        }
      }
    },
    addApplicantsToSlot(sendInvitation) {
      this.selectedRowCount = null;
      const selectedSlotData = this.slotsList.find((slot) => {
        return slot.id == this.selectedSlotId;
      });
      const form_id = selectedSlotData.form_id;
      const selectedInstanceId = selectedSlotData.instance_id;
      const selectedCourseId = selectedSlotData.course_id;
      this.selectedApplicants = [];
      const data = this.dropData.map((applicant) => {
        return {
          ...applicant,
          form_id,
          selectedInstanceId,
          selectedCourseId,
          sendInvitation,
        };
      });
      if (this.scheduleDateInvalid(selectedSlotData.to_time)) {
        this.$store.dispatch(
          "openSnackbar",
          { type: "danger", message: "Selected Slot has been expired!" },
          { root: true }
        );
      } else {
        let alreadyExist = 0;
        const oldIds = this.receivedApplicants.map((applicant) => applicant.id);
        const existingId = this.selectedSlotData.applicants.map(
          (applicant) => applicant.id
        );
        const newFilterApplicants = data.filter((applicant) => {
          if (
            oldIds.includes(applicant.id) ||
            existingId.includes(applicant.id)
          ) {
            alreadyExist = alreadyExist + 1;
            return false;
          } else {
            return true;
          }
        });
        if (alreadyExist == 0 && newFilterApplicants.length > 0) {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Dropped applicants added locally." },
            { root: true }
          );
        } else if (alreadyExist > 0 && newFilterApplicants.length > 0) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message:
                "Dropped applicants added locally. Already existed applicants not added!",
            },
            { root: true }
          );
        } else if (alreadyExist > 0 && newFilterApplicants.length == 0) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Already existed applicants not added!",
            },
            { root: true }
          );
        }
        const mappedData = newFilterApplicants.map((applicant) => {
          return {
            ...applicant,
            not_uploaded: true,
          };
        });
        this.receivedApplicants = [...mappedData, ...this.receivedApplicants];
        let applicantIds = this.receivedApplicants.map((e) => e.id);
        applicantIds = applicantIds.filter(
          (e) => this.findNested(e, this.instanceCourses) === null
        );
        if (selectedInstanceId && selectedCourseId) {
          if (this.instanceCourses[selectedInstanceId]) {
            if (this.instanceCourses[selectedInstanceId][selectedCourseId]) {
              this.instanceCourses[selectedInstanceId][selectedCourseId].push(
                ...applicantIds
              );
            } else {
              this.instanceCourses[selectedInstanceId][selectedCourseId] = [
                ...applicantIds,
              ];
            }
          } else {
            this.instanceCourses[selectedInstanceId] = {
              [selectedCourseId]: [...applicantIds],
            };
          }
        }
        this.gridOptions.api.setGridOption(
          "rowData",
          this.filterApplicants([
            ...this.receivedApplicants,
            ...this.selectedSlotData.applicants,
          ])
        );
      }
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description filter applicants by status
     */
    filterApplicants(applicants) {
      const filteredList = applicants.filter((applicant) => {
        if (this.filterSelected == "all") {
          return true;
        } else if (this.filterSelected == "not uploaded") {
          if (applicant.not_uploaded == true) {
            return true;
          }
        } else if (this.filterSelected == "scheduled") {
          if (
            applicant.not_uploaded != true &&
            applicant.roundData &&
            applicant.roundData[0].round_result == "Pending"
          ) {
            return true;
          }
        } else if (this.filterSelected == "passed") {
          if (
            applicant.not_uploaded != true &&
            applicant.roundData &&
            applicant.roundData[0].round_result == "Passed"
          ) {
            return true;
          }
        } else if (this.filterSelected == "cancelled") {
          if (
            applicant.not_uploaded != true &&
            applicant.roundData &&
            applicant.roundData[0].round_result == "Cancelled"
          ) {
            return true;
          }
        } else if (this.filterSelected == "failed") {
          if (
            applicant.not_uploaded != true &&
            applicant.roundData &&
            applicant.roundData[0].round_result == "Failed" &&
            applicant.not_uploaded != true
          ) {
            return true;
          }
        }
      });
      return filteredList;
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description handle upload applicants to slot
     */
    handleUpload() {
      if (this.receivedApplicants.length > 0) {
        const ids = this.receivedApplicants.map((applicant) => {
          return {
            applicant_id: applicant.id,
            form_id: applicant.form_id ? applicant.form_id : [],
            mail_template: applicant.mail_template,
            selectedInstanceId: applicant.selectedInstanceId,
            selectedCourseId: applicant.selectedCourseId,
            sendInvitation: applicant.sendInvitation,
          };
        });
        const addSlotApplicantsData = {
          eventId: this.eventData.EventDetails.id,
          applicantsId: ids,
          slotId: this.selectedSlotId,
          roundId: this.eventData.EventDetails.id,
        };
        api
          .addApplicantsToSlot(addSlotApplicantsData)
          .then(async () => {
            this.fetchEventSlots();
            this.receivedApplicants = [];
            this.getSlotData(this.selectedSlotId);
            this.refreshGrid();
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Applicants has been added to slot.",
              },
              { root: true }
            );
          })
          .catch((err) => {
            console.log("err", err);
            this.fetchEventSlots();
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: err.response.data.message
                  ? err.response.data.message
                  : "Something went wrong",
              },
              {
                root: true,
              }
            );
          });
      }
    },
    courseModalCanceled() {
      this.dropData = null;
    },
    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description method to call function on ag grid selection change
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description check if row selectable in slot applicants list
     */
    isRowSelectable(rowNode) {
      return rowNode.data
        ? (rowNode.data.roundData &&
            rowNode.data.roundData.length > 0 &&
            rowNode.data.roundData[0].round_result == "Pending") ||
            rowNode.data.not_uploaded
        : false;
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description apply row styles by status
     */
    getRowStyle(params) {
      if (params.node.data.not_uploaded) {
        return { background: "#dee2e6" };
      } else if (params.node.data.roundData[0].round_result === "Passed") {
        return { background: "#b2ff8f" };
      } else if (params.node.data.roundData[0].round_result === "Failed") {
        return { background: "#f58f82" };
      } else if (params.node.data.roundData[0].round_result === "Cancelled") {
        return { background: "#fff396" };
      }
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description select all applicant on current page
     */
    selectAllOnCurrentPage() {
      if (this.gridOptions && this.gridOptions.api) {
        const maxNumbers =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);
        this.gridOptions.api.forEachNodeAfterFilterAndSort((node, index) => {
          node.setSelected(index < maxNumbers);
        });
      }
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description deselect all applicant on current page
     */
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description handle on change in pag size
     */
    selectFilterApplicant(event) {
      this.filterSelected = event.target.value;
      this.selectedRowCount = null;
      this.selectedApplicants = [];
    },

    findNested(value, data) {
      for (const instance in data) {
        for (const course in data[instance]) {
          if (data[instance][course].includes(value)) {
            return { instance, course };
          }
        }
      }
      return null;
    },

    /**
     * @memberof module:CampusRecruitment.slot-page
     * @description remove applicant from slot locally
     */
    removeSelectedFromSlot() {
      let notUploadedApplicantsId = [];
      let uploadedApplicantsId = [];
      let uploadedApplicantsSlugs = [];
      let instanceId;
      this.selectedApplicants.forEach((applicant) => {
        if (applicant.not_uploaded) {
          notUploadedApplicantsId.push(applicant.id);
        } else {
          instanceId =
            applicant.roundData[0]?.hasMoodle?.moodle_user_master
              ?.moodle_instance_id;
          uploadedApplicantsId.push({
            applicantId: applicant.id,
            instanceId,
            roundId: applicant.roundData[0]?.id,
          });
          uploadedApplicantsSlugs.push(applicant.slug);
        }
      });
      if (
        this.eventData.EventDetails.round_name === "Written Test" &&
        notUploadedApplicantsId.length > 0
      ) {
        for (const id of notUploadedApplicantsId) {
          const { instance, course } = this.findNested(
            id,
            this.instanceCourses
          );
          this.instanceCourses[instance][course] = this.instanceCourses[
            instance
          ][course].filter((val) => val !== id);
          if (this.instanceCourses[instance][course].length <= 0) {
            delete this.instanceCourses[instance][course];
          }
        }
      }

      const newReceivedApplicants = this.receivedApplicants.filter(
        (applicant) => {
          return !notUploadedApplicantsId.includes(applicant.id);
        }
      );
      this.receivedApplicants = newReceivedApplicants;
      if (uploadedApplicantsId.length > 0) {
        const data = {
          id: this.selectedSlotId,
          applicantsId: uploadedApplicantsId,
          mail_template: this.cancelRoundMailTemplateId,
          eventId: this.eventData.EventDetails.id,
        };
        api
          .removeApplicantsFromSlot(data)
          .then(() => {
            this.fetchEventSlots();
            this.selectedRowCount = null;
            this.selectedApplicants = [];
            this.getSlotData(this.selectedSlotId);
            this.setDataSource();
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Selected applicants has been removed from slot!",
              },
              { root: true }
            );
          })
          .catch(() => {
            this.fetchEventSlots();
            this.selectedRowCount = null;
            this.selectedApplicants = [];
            this.getSlotData(this.selectedSlotId);
            this.setDataSource();
            this.$store.dispatch(
              "openSnackbar",
              { type: "danger", message: "Something went wrong" },
              { root: true }
            );
          });
      } else {
        this.gridOptions.api.setGridOption("rowData", [
          ...this.receivedApplicants,
          ...this.selectedSlotData.applicants,
        ]);
        this.selectedRowCount = null;
        this.selectedApplicants = [];
      }
    },
    openPassQualified() {
      $("#passQualified").modal("show");
    },
    sendGoogleMeet() {
      const pendingApplicants = [];
      for (const applicant of this.selectedApplicants) {
        if (
          applicant.roundData &&
          applicant.roundData.length > 0 &&
          applicant.roundData[0].round_result == "Pending" &&
          !applicant.not_uploaded
        ) {
          pendingApplicants.push(applicant.email);
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select only pending applicants",
            false,
            "danger",
            "modal-m"
          );
          return;
        }
      }
      if (pendingApplicants.length == 0) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return;
      }
      for (const interviewer of this.selectedSlotData.interviewers) {
        pendingApplicants.push(interviewer.email);
      }
      const data = {
        emails: [...new Set(pendingApplicants)],
        from_time: this.selectedSlotData.from_time,
        to_time: this.selectedSlotData.to_time,
        position: "Fresher",
      };
      api
        .sendGoogleMeetInvitation(data)
        .then(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Google meet link sent successfully!" },
            { root: true }
          );
        })
        .catch((err) => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message:
                err.response && err.response.data.message
                  ? err.response.data.message
                  : "Something went wrong",
            },
            { root: true }
          );
        });
    },
    onPassQualified() {
      this.getSlotData(this.selectedSlotId);
      this.setDataSource();
    },
    onPaginationChanged() {
      if (this.gridOptions && this.gridOptions.api) {
        this.maxApplicantCountToCurrentPage =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);

        let totalSelectable = 0;
        this.gridOptions.api.forEachNodeAfterFilterAndSort((node, index) => {
          const isSelectable = node.data
            ? (node.data.roundData &&
                node.data.roundData.length > 0 &&
                node.data.roundData[0].round_result == "Pending") ||
              node.data.not_uploaded
            : false;
          if (isSelectable && index < this.maxApplicantCountToCurrentPage) {
            totalSelectable++;
          }
        });
        this.totalSelectable = totalSelectable;
      }
    },
    configGridOptions() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.gridOptions = {
        rowData: null,
        columnDefs: this.columnDefs,
        onRowClicked: () => {},
        onColumnResized: () => {},
        onPaginationChanged: (event) => this.onPaginationChanged(event),
        onSelectionChanged: () => {
          this.onSelectionChanged();
        },
        // getRowStyle: (event) => { this.getRowStyle(event) },
        isRowSelectable: (event) => this.isRowSelectable(event),
        headerHeight: 30,
        rowHeight: 30,
        // getRowHeight: (params) => 25,
        suppressPropertyNamesCheck: true,
        suppressRowClickSelection: true,
        enableCellTextSelection: true,
        enableServerSideFilter: true,
        enableColResize: true,
        rowSelection: "multiple",
        // rowDeselection: true,
        // rowModelType: "infinite",
        defaultColDef: {
          flex: 1,
          floatingFilter: true,
          resizable: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          // set filtering on for all columns
          filter: true,
        },
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
    getUsers() {
      if (
        !this.hasAccess({
          permissions: [
            this.BASE_PERMISSION["SCHEDULE-CREATE"],
            this.BASE_PERMISSION["SCHEDULE-UPDATE"],
          ],
          checkAll: false,
        })
      )
        return;
      this.$store.dispatch("$_applicant_profile/usersDetails");
    },
    getFormsTemplates() {
      if (
        !this.hasAccess({
          permissions: [
            this.BASE_PERMISSION["SCHEDULE-CREATE"],
            this.BASE_PERMISSION["SCHEDULE-UPDATE"],
          ],
          checkAll: false,
        })
      )
        return;
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
    passAllSelectedApplicant(action) {
      const validApplicants = [];

      this.selectedApplicants.forEach((applicant) => {
        if (this.passSelectedApplicantRound(applicant, action)) {
          validApplicants.push(applicant.roundData);
        }
      });

      if (validApplicants.length > 0) {
        this.showAddScoreModal(validApplicants, action);
      } else {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "danger",
            message: "No valid applicants found",
          },
          { root: true }
        );
      }

      // After processing, clear the selectedApplicants array
      this.selectedApplicants = [];
    },
    failAllSelectedApplicants(action) {
      const validApplicants = [];

      this.selectedApplicants.forEach((applicant) => {
        if (this.failSelectedApplicantRound(applicant, action)) {
          validApplicants.push(applicant.roundData);
        }
      });

      if (validApplicants.length > 0) {
        this.showAddScoreModal(validApplicants, action);
      } else {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "danger",
            message: "No valid applicants found",
          },
          { root: true }
        );
      }
      // After processing, clear the selectedApplicants array
      this.selectedApplicants = [];
    },
  },
  beforeMount() {
    this.configGridOptions();
  },
  created() {
    this.getUsers();
    this.getFormsTemplates();
  },
};
</script>

<style scoped>
.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 185px);
}
</style>
