<template>
  <div>
    <label :for="'input-line-' + _uid" v-if="showLabels">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="input-parent">
      <b-form-rating
        :stars="properties.stars"
        :value="value"
        @change="handleInput"
        :disabled="disabled"
        v-bind="inputAttributes.attrs"
        v-on="inputAttributes.vOn"
        :class="[inputAttributes.class || 'mt-2']"
      ></b-form-rating>
    </div>
    <template v-if="showErrors">
      <div class="d-flex row mx-0">
        <div class="col-6 mt-2">
          <p>{{ properties.lLabel }}</p>
        </div>
        <div class="col-6 mt-2 text-right">
          <p>{{ properties.rLabel }}</p>
        </div>
      </div>
      <div class="col-12 my-2">
        <p class="text-danger" v-for="(error, idx) in errorList" :key="idx">
          <b-icon-exclamation-circle></b-icon-exclamation-circle>
          <span class="ms-2">{{ error }}</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { processAttributes } from "@/modules/User/_utils/actions";
import validators from "../../_utils/validators";

export default {
  name: "rating-input-builder",
  props: {
    value: { type: [String, Number] },
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
    disabled: { type: Boolean, default: false },
    inputAttrs: { type: Object, default: () => ({}) },
    showLabels: { type: Boolean, default: true },
    showErrors: { type: Boolean, default: true },
  },
  data() {
    return {
      errorList: [],
    };
  },
  computed: {
    inputAttributes() {
      return processAttributes(this.inputAttrs);
    },
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];
      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput(e) {
      this.$emit("input", +e);
      this.useValidation && this.checkError(+e);
    },
    handleChange(e) {
      this.$emit("input", e);
    },
  },
};
</script>
