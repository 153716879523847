import axios from "axios";

/**
 * @memberOf module:ApplicantProfile.api
 * @param {String} id
 * @returns {Promise}
 * @description Fetch ApplicantProfile By Id
 */
const fetchApplicantData = (slug) => {
  return axios.get("applicants/by/slug/" + slug);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {String} id
 * @returns {Promise}
 * @description Fetch ApplicantProfile By Id
 */
const fetchApplicantPic = (slug) => {
  return axios.get("applicants/profile-pic/" + slug);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {String} applicant_id
 * @returns {Promise}
 * @description Fetch Applicant round data
 */
const fetchRoundDetails = (applicant_id) => {
  return axios.get("rounds/round_schedules/applicant/" + applicant_id);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} roundData
 * @returns {Promise}
 * @description Add Round for Applicant
 */
const addRound = (roundData) => {
  let data;
  if (Array.isArray(roundData)) {
    data = { data: roundData };
  } else {
    data = {
      data: [roundData],
    };
  }
  return axios.post("rounds/round_schedules", data);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} roundData
 * @returns {Promise}
 * @description Edit Round Data for Applicant
 */
const editRound = (roundData) => {
  let stringifiedRoundData = JSON.stringify(roundData);
  stringifiedRoundData = JSON.parse(stringifiedRoundData);
  delete stringifiedRoundData.createdAt;
  delete stringifiedRoundData.updatedAt;
  delete stringifiedRoundData.interviewers;
  delete stringifiedRoundData.reviewers;
  delete stringifiedRoundData.created_by;
  delete stringifiedRoundData.updated_by;
  delete stringifiedRoundData.applicantIterationMaster;
  const data = {
    data: stringifiedRoundData,
  };
  return axios.put("rounds/round_schedules", data);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} roundData
 * @returns {Promise}
 * @description Cancel Round for Applicant
 */
const cancelRound = (roundData) => {
  const data = {
    data: {
      id: roundData.id,
      round_result: roundData.round_result,
      round_name: roundData.round_name,
      applicantIterationMasterId: roundData.applicantIterationMasterId,
    },
  };
  return axios.put("rounds/round_schedules", data);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} applicationData
 * @returns {Promise}
 * @description Edit main application status of applicant
 */
const editApplicationStatus = (applicationData) => {
  const data = {
    ...(applicationData.application_status === "Shortlisted" && {
      designation_offered: applicationData.designation_offered,
      package_offered: applicationData.package_offered,
      expected_joining: applicationData.expected_joining,
      jobId: applicationData.jobId,
    }),
    id: applicationData.id,
    application_status: applicationData.application_status,
    application_comments: applicationData.application_comments,
  };

  let formData = new FormData();

  let userData = JSON.stringify(data);
  formData.append("data", userData);
  return axios.put("applicants/applicant", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} interviewReview
 * @returns {Promise}
 * @description Add Interview Review of a round
 */
const addInterviewReview = (interviewReview) => {
  return axios.put("rounds/interview_panel", interviewReview);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} interviewReview
 * @returns {Promise}
 * @description Add Multiple Interview Review of a round
 */
const addMultipleInterviewReview = (interviewReview) => {
  return axios.put("rounds/multiple/interview_panel", interviewReview);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Object} reviewRoundReview
 * @returns {Promise}
 * @description Add Review of a review round
 */
const addReviewRoundReview = (reviewRoundReview) => {
  return axios.put("rounds/review_panel", reviewRoundReview);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Get all users available
 */
const usersDetails = () => {
  return axios.get("users");
};

const userProfilePicture = (id) => {
  return axios.get("users/profile-pic/" + id);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Add comment
 */
const addComment = (comment) => {
  const data = {
    data: comment,
  };
  return axios.post("applicants/comments", data);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Delete Comment by Id
 */
const deleteCommentById = (data) => {
  return axios.delete("applicants/comments/" + data.item.id);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Update Comment
 */
const updateComment = (data) => {
  return axios.put("applicants/comments", {
    data: data,
  });
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Get comment_list
 */
const getCommentListByApplicantId = (applicantId) => {
  return axios.get("applicants/comments/by/applicant/" + applicantId);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Get all job profiles
 */
const getJobProfiles = () => {
  return axios.get("jobs");
};

/**
 * @memberOf module:ApplicantProfile.api
 * @returns {Promise}
 * @description Get applicant claimed_by user details
 */
const getClaimedByDetails = (id) => {
  return axios.get("applicants/claimed_by/" + id);
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {Number} id applicant id
 * @param {Number} claimed_by applicant claimed_by value
 * @returns {Promise}
 * @description Update applicant claimed_by value
 */
const updateClaimedById = (id, action) => {
  return axios.post("applicants/applicant/claimed_by/update", { id, action });
};

const fetchApplicantRoundScore = (round_id) => {
  return axios.get("applicants/applicant/get-round-score/" + round_id);
};

const getFollowUp = (applicantId) => {
  return axios.get("applicants/followup/by/applicant/" + applicantId);
};

const addFollowUp = (data) => {
  return axios.post("applicants/followup/update", { data });
};

const updateFollowUp = (data) => {
  return axios.put("applicants/followup/update/active_status", { data });
};
/**
 * @memberOf module:ApplicantProfile
 * @namespace api
 * @description Api for applicant profile
 */
export default {
  fetchApplicantData,
  fetchRoundDetails,
  addRound,
  editRound,
  cancelRound,
  editApplicationStatus,
  addInterviewReview,
  addMultipleInterviewReview,
  addReviewRoundReview,
  usersDetails,
  userProfilePicture,
  addComment,
  deleteCommentById,
  updateComment,
  getCommentListByApplicantId,
  getJobProfiles,
  getClaimedByDetails,
  updateClaimedById,
  fetchApplicantRoundScore,
  getFollowUp,
  addFollowUp,
  updateFollowUp,
  fetchApplicantPic,
};
