<template>
  <div>
    <div class="content">
      <common-header :data="['home', 'previewJoiningForm']" type="applicant"
        ><router-link
          class="btn ml-2 btn-basic"
          :to="{
            name: 'editJoiningForm',
          }"
          ><i
            class="fa fa-pencil-square-o cst-color"
            aria-hidden="true"
          ></i></router-link
      ></common-header>
      <div class="col-md-12">
        <h4><strong>Personal Details</strong></h4>
        <div class="col-sm-12 col-12 pr-sm-2 mb-3">
          <div class="my-2 text-center">
            <img
              v-if="joiningProfilePic !== null"
              :src="joiningProfilePic"
              alt="Profile Picture"
              class="mx-auto d-block rounded-circle"
              style="width: 200px; height: 200px; object-fit: cover"
            />
          </div>
        </div>
        <div class="well">
          <table
            class="table table-condensed table-compressed"
            aria-label="Education Details"
            aria-hidden="true"
          >
            <tbody>
              <tr>
                <td class="col-md-2"><strong>First Name</strong></td>
                <td class="col-md-4">{{ data.first_name }}</td>
                <td class="col-md-2"><strong>Last Name</strong></td>
                <td class="col-md-4">{{ data.last_name }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Father's Name</strong></td>
                <td class="col-md-4">{{ data.fatherName }}</td>
                <td class="col-md-2"><strong>Mother's Name</strong></td>
                <td class="col-md-4">{{ data.motherName }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Email</strong></td>
                <td class="col-md-4">{{ data.email }}</td>
                <td class="col-md-2"><strong>Secondary Email</strong></td>
                <td class="col-md-4">{{ data.secondary_email }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Mobile Number</strong></td>
                <td class="col-md-4">{{ data.mobile_no }}</td>
                <td class="col-md-2"><strong>Date of Birth</strong></td>
                <td class="col-md-4">{{ data.dob }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Name As Per Aadhaar</strong></td>
                <td class="col-md-4">{{ data.nameAsPerAadhaar }}</td>
                <td class="col-md-2"><strong>Aadhaar Number</strong></td>
                <td class="col-md-4">{{ data.aadhaarNumber }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>PAN Number</strong></td>
                <td class="col-md-4">{{ data.panNumber }}</td>
                <template v-if="data.uanNumber">
                  <td class="col-md-2"><strong>UAN Number</strong></td>
                  <td class="col-md-4">{{ data.uanNumber }}</td>
                </template>
                <template v-else>
                  <td class="col-md-2"></td>
                  <td class="col-md-4"></td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-12">
        <h4><strong>Uploaded Documents</strong></h4>

        <div class="documents-container">
          <div
            v-for="(doc, index) in data.documents"
            :key="index"
            class="document-card"
          >
            <document-preview
              :title="formatDocumentType(doc.type)"
              :id="doc.type"
              :documentUrl="doc.url"
              :isApplicant="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentPreview from "./document-upload-preview.vue";
import applicantAxios from "../_utils/axios";

export default {
  data() {
    return {
      isEditMode: false,
      joiningProfilePic: null,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    DocumentPreview,
  },
  methods: {
    formatDocumentType(type) {
      return type
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before capital letters
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
    },
    editJoiningForm() {
      this.$router.push({
        name: "editJoiningForm",
      });
    },
    async fetchProfilePic() {
      try {
        const fileId = this.data?.documents?.passportPhoto?.url;
        if (fileId) {
          const url = `/applicant-master/joining/documents/view/passportPhoto?FileId=${fileId}`;
          const response = await applicantAxios.get(url, {
            responseType: "blob",
          });

          const blobUrl = window.URL.createObjectURL(response.data);

          // Store the Blob URL in the component state
          this.joiningProfilePic = blobUrl;
        }
      } catch (error) {
        console.error("Error fetching the profile picture:", error);
      }
    },
  },
  mounted() {
    this.fetchProfilePic();
  },
};
</script>
<style scoped>
.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 10px;
}

.document-card {
  flex: 1 1 calc(25% - 16px);
  max-width: calc(25% - 16px);
  box-sizing: border-box;
}

/* Large screens (1024px to 1199px) */
@media (max-width: 1199px) {
  .document-card {
    flex: 1 1 calc(33.33% - 16px); /* 3 cards per row */
    max-width: calc(33.33% - 16px);
  }
}

/* Medium screens (768px to 1023px) */
@media (max-width: 1023px) {
  .document-card {
    flex: 1 1 calc(50% - 16px); /* 2 cards per row */
    max-width: calc(50% - 16px);
  }
}

/* Small screens (481px to 767px) */
@media (max-width: 767px) {
  .document-card {
    flex: 1 1 100%; /* 1 card per row */
    max-width: 100%;
  }
}

/* Mobile screens (480px and below) */
@media (max-width: 480px) {
  .document-card {
    flex: 1 1 100%; /* 1 card per row */
    max-width: 100%;
  }
}
</style>
