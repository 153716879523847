<template>
  <div style="width: 100%" class="content-header">
    <div class="container-fluid py-1">
      <div
        class="breadcrumb-parent d-flex align-items-center justify-content-between p-0"
        style="flex-wrap: wrap"
      >
        <!-- Breadcrumb -->
        <b-breadcrumb class="d-flex align-items-center m-0 p-0 py-2">
          <b-breadcrumb-item
            v-b-tooltip.hover.html.bottom="option.tooltip"
            class="d-flex align-items-center"
            :active="option.active"
            v-for="(option, index) in dataOptions"
            :key="index"
          >
            <router-link
              v-if="option.type == 'page'"
              :to="{
                name: option.name,
                ...(option.params ? option.params : {}),
              }"
            >
              <i v-if="option.icon" :class="option.icon" aria-hidden="true"></i
              >{{ option.value ? option.value : option.key }}
            </router-link>
            <div v-else>
              <a class="px-0" data-toggle="dropdown">
                {{ option.key }}
              </a>
              <div class="dropdown-menu z-3">
                <div v-for="(suboption, index) in option.options" :key="index">
                  <router-link
                    class="dropdown-item"
                    :to="{
                      name: suboption.name,
                      ...(suboption.params ? suboption.params : {}),
                    }"
                    v-if="suboption.permission"
                  >
                    {{ suboption.key }}
                  </router-link>
                </div>
              </div>
            </div>
          </b-breadcrumb-item>
        </b-breadcrumb>
        <!-- Slot -->
        <div class="align-right ml-auto"><slot></slot></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "employee",
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "employee",
    },
    componentName: {
      type: String,
      default: "",
    },
    componentTooltip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: {
        home: {},
        recruiter: {},
        applicant: {
          type: "option",
          permission: true,
          options: {
            home: {
              key: "home",
              name: "applicantApplications",
              type: "page",
              permission: true,
              options: {
                profile: {
                  key: "Profile",
                  name: "applicantProfile",
                  type: "page",
                  permission: true,
                },
                applications: {
                  key: "Your Applications",
                  name: "applicantApplications",
                  type: "page",
                  permission: true,
                  options: {
                    singleApplication: {
                      name: "applicantApplicationBySlug",
                      type: "page",
                      permission: true,
                    },
                  },
                },
                jobOpenings: {
                  key: "Job Openings",
                  name: "applicantJobOpenings",
                  type: "page",
                  permission: true,
                  options: {
                    singleJob: {
                      name: "applicantJobProfile",
                      type: "page",
                      permission: true,
                    },
                  },
                },
                linkRequest: {
                  key: "Link Application Requests",
                  name: "linkApplicationRequest",
                  type: "page",
                  permission: true,
                },
                joiningDocumentForm: {
                  key: "Joining Form",
                  name: "joiningDocument",
                  type: "page",
                  permission: true,
                  options: {
                    editJoiningForm: {
                      name: "editJoiningForm",
                      type: "page",
                      permission: true,
                    },
                  },
                },
                previewJoiningForm: {
                  key: "Preview Joining Form",
                  name: "joiningDocument",
                  type: "page",
                  permission: true,
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      employeeCommonHeader: "user/commonHeader",
    }),
    dataOptions() {
      if (
        this.type === "employee" &&
        !Object.keys(this.employeeCommonHeader).length
      )
        return;
      let ddd = [];
      this.data.forEach((d, index) => {
        const data = this.getData(index);
        if (this.componentName && this.data.length == index + 1) {
          data.key = this.componentName;
          data.tooltip = this.componentTooltip;
        }
        ddd.push({ ...data, active: this.data.length == index + 1 });
      });
      return ddd;
    },
  },
  methods: {
    getData(index) {
      let result = this.employeeCommonHeader;
      if (this.type === "home") result = this.options.home;
      if (this.type === "recruiter") result = this.options.recruiter;
      if (this.type === "applicant") result = this.options.applicant;
      for (const k of this.data.slice(0, index + 1)) {
        if (result && typeof result === "object") {
          let key = null;
          if (typeof k == "string") {
            key = k;
            result = result["options"][key];
          } else {
            key = k.key;
            result = { ...result["options"][key], value: k.value };
          }
        } else {
          result = undefined;
          break;
        }
      }
      return result;
    },
  },
};
</script>
<style scoped>
.breadcrumb-parent {
  height: auto;
  padding: 10px;
}
@media screen and (max-width: 583px) {
  .align-left {
    align-self: flex-start;
  }
  .align-right {
    align-self: flex-end;
  }
}
.breadcrumb {
  background-color: transparent;
  font-size: 16px;
}
.breadcrumb a {
  font-size: 16px;
  text-transform: capitalize;
}
.breadcrumb-item.active a {
  cursor: text;
  color: #903564 !important;
  font-size: 16px;
}
.router-link-exact-active {
  color: #903564 !important;
}
h1 {
  margin-bottom: 15px;
  margin-top: 0px;
  padding: 20px 30px;
  font-weight: 500;
  font-size: 28px;
  color: #4d4d4d;
  border-bottom: 1px solid #ebebeb;
  text-align: center;
}
@media (max-width: 576px) {
  .sm-px-1 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
</style>
