<template>
  <div>
    <div v-if="joiningData?.aadhaarNumber && joiningData?.isjoining">
      <preview-joining-data :data="joiningData" />
    </div>
    <div v-if="joiningData?.isjoining && !joiningData?.aadhaarNumber">
      <joining-document-form />
    </div>
  </div>
</template>

<script>
import previewJoiningData from "./preview-joining-doc.vue";
import joiningDocumentForm from "./joining-documents.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    previewJoiningData,
    joiningDocumentForm,
  },
  computed: {
    ...mapGetters({
      joiningData: "$_applicant_master/getJoiningData",
    }),
  },
  created() {
    this.$store.dispatch("$_applicant_master/getJoiningData");
  },
  watch: {
    joiningData(newData) {
      if (newData && !newData.isjoining) {
        this.$router.push({ name: "401" });
      }
    },
  },
};
</script>
