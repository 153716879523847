<template>
  <div class="content">
    <common-header
      id="commonHeader"
      class="mt-5"
      :data="['home', 'manage', 'applicantMaster']"
    >
    </common-header>
    <div class="container-fluid">
      <div style="width: 100%">
        <ag-grid-vue
          class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
          :enableBrowserTooltips="true"
          :gridOptions="gridOptions"
          :rowData="rowData"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import { getFilterColumns } from "../../Common/_plugin/validation";
import commonHeader from "@/modules/Common/_components/common-header.vue";

export default {
  data() {
    return {
      gridOptions: null,
      columnDefs: null,
    };
  },
  components: {
    "ag-grid-vue": AgGridVue,
    commonHeader,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantmasterprofile', params:{id:params.data.id}}"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
  },
  methods: {
    createColumnDefs() {
      this.columnDefs = [
        {
          cellRenderer: "profile-link",
          width: 35,
          minWidth: 35,
          maxWidth: 35,
          suppressFilter: true,
          filter: false,
          valueGetter: "node.id",
          headerTooltip: "Profile Info",
          tooltipValueGetter: function (params) {
            if (params.data != undefined) {
              return "Profile of " + params.data.first_name;
            }
            return "";
          },
          pinned: "left",
          cellStyle: { textAlign: "center", padding: 0 },
          display_only: true,
        },
        {
          headerName: "Name",
          field: "name",
          valueGetter: function mergeLastNameFirstName(params) {
            if (params.data != undefined) {
              return params.data.first_name + " " + params.data.last_name;
            }
          },
          sortable: true,
          comparator: (valueA, valueB) => {
            if (valueA && valueB) {
              return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            }
            return 0;
          },
          width: 200,
          minWidth: 200,
          suppressHeaderMenuButton: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Employment Status",
          field: "employment_status",
          sortable: true,
          width: 140,
          minWidth: 140,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          comparator: (valueA, valueB) => {
            if (valueA && valueB) {
              return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            }
            return 0;
          },
          width: 200,
          minWidth: 200,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Mobile",
          field: "mobile_no",
          sortable: true,
          width: 100,
          minWidth: 100,
          suppressHeaderMenuButton: true,
        },
      ];
    },
  },

  beforeMount() {
    this.createColumnDefs();
    this.gridOptions = {
      headerHeight: 35,
      rowHeight: 37,
      columnDefs: getFilterColumns(this.columnDefs, 1),
      suppressPropertyNamesCheck: true,
      enableServerSideFilter: true,
      enableCellTextSelection: true,
      enableColResize: true,
      defaultColDef: {
        flex: 1,
        floatingFilter: true,
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true,
          buttons: ["reset"],
        },
        filter: true,
      },
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      context: {
        componentParent: this,
      },
    };
  },
  computed: {
    ...mapGetters({
      rowData: "$_applicants_data/getApplicantMasterData",
    }),
  },
  mounted() {
    this.$store.dispatch("$_applicants_data/fetchApplicantMasterData");
  },
};
</script>
<style scoped>
.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 135px);
}

@media screen and (max-width: 297px) {
  .ag-grid-style {
    height: calc(100vh - 170px);
  }
}
</style>
