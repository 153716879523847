import Vue from "vue";
import Router from "vue-router";

import Home from "../views/Home.vue";

import Dashboard from "../views/Dashboard.vue";
import RecruiterDashboard from "../views/RecruiterDashboard.vue";
import CampusRecruitmentForm from "../modules/CampusRecruitment/_components/CampusRecruitmentForm.vue";
import ManageJobRoutes from "../modules/ManageJob/_routes";
import ManageFileRoutes from "../modules/ManageFiles/_routes";
import FormTemplateRoutes from "../modules/FormTemplate/_routes";
import FileTemplateRoutes from "../modules/FileTemplate/_routes";
import ManageReviewRoutes from "../modules/ManageReview/_routes";
import HomeRoutes from "../modules/Home/_routes";
import MailRoutes from "../modules/MailBox/_routes";
import ApplicantProfileRoutes from "../modules/ApplicantProfile/_routes";
import ResolveDuplicateRoutes from "../modules/ResolveDuplicate/_routes";
import CampusRecruitmentRoutes from "../modules/CampusRecruitment/_routes";
import RecruiterRoutes from "../modules/Recruiter/_routes";
import ScheduleDashboardRoutes from "../modules/ScheduleDashboard/_routes";
import PageNotFound from "../modules/Login/404-page.vue";
import HomepageRoutes from "../modules/Dashboard/_routes";
import EditApplicationForm from "../modules/EditApplicantData/_routes";
import VueCookie from "vue-cookie";
import AuditTrailRoutes from "../modules/AuditTrail/_routes";
import ReportRoutes from "../modules/Report/_routes";
import ApplicantMasterRoutes from "../modules/ApplicantMaster/_routes";
import ApplicantMaster from "../modules/ApplicantMaster/index.vue";
import store from "../store/index";
import ApplicantLinkRequests from "../modules/Applications/_components/applicant-link-requests.vue";
import SharedTokenRoutes from "../modules/SharedToken/_routes";
import ApplicationsRoute from "../modules/Applications/_routes";
import ApplicantMasterProfile from "../modules/ApplicantProfile/_components/applicant-master-profile.vue";
import config from "../config";
import {
  hasAccess,
  setLoggedInUser,
  setPermissions,
  setPublicKeys,
} from "../modules/User/_utils/actions";
import { addSnackbarMessage } from "../modules/Common/_plugin/error-response";
import {
  addRecruiterModule,
  setLoggedInRecruiter,
} from "../modules/Recruiter/_utils/actions";
import systemConfig from "../modules/SystemConfig/_routes";
import {
  addApplicantMasterModule,
  setLoggedInApplicant,
} from "../modules/ApplicantMaster/_utils/actions";
import CronRoutes from "../modules/CronJob/_routes";
import axios from "axios";

Vue.use(Router);

const router = new Router({
  base: config.ROUTER_BASE,
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      children: HomeRoutes,
    },

    {
      path: "/dashboard",
      component: Dashboard,

      children: [
        // ApplicantDataRoutes,
        HomepageRoutes,
        MailRoutes,
        ManageReviewRoutes,
        ManageJobRoutes,
        ManageFileRoutes,
        FormTemplateRoutes,
        FileTemplateRoutes,
        ApplicantProfileRoutes,
        // RoundSchedulesRoutes,
        ResolveDuplicateRoutes,
        CampusRecruitmentRoutes,
        ScheduleDashboardRoutes,
        EditApplicationForm,
        AuditTrailRoutes,
        ReportRoutes,
        CronRoutes,
        systemConfig,
        {
          path: "applicant-profile/:id",
          name: "applicantmasterprofile",
          component: ApplicantMasterProfile,
          meta: {
            title: "Applicant Master Profile",
            user: "employee",
          },
        },
        {
          path: "link-requests",
          component: ApplicantLinkRequests,
          name: "applicantLinkRequests",
          meta: {
            title: "All Link Requests",
            user: "employee",
          },
        },
        SharedTokenRoutes,
        ApplicationsRoute,
      ],
    },
    {
      path: "/recruiter",
      component: RecruiterDashboard,
      children: RecruiterRoutes,
    },
    {
      path: "/applicant",
      component: ApplicantMaster,
      children: ApplicantMasterRoutes,
    },
    {
      path: "/campus-recruitment-form/:id",
      name: "campusRecruitmentForm",
      component: CampusRecruitmentForm,
      meta: {
        title: "Argusoft Recruitment Form",
      },
    },
    {
      path: "*",
      component: PageNotFound,
      meta: {
        title: "Page Not Found",
      },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

let apiAbortControllers = {};
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  const pathname = window.location.pathname;
  //if no controller is found for the pathname then create a new abortController
  if (!apiAbortControllers[pathname]) {
    apiAbortControllers[pathname] = new AbortController();
  }
  // If abortRequest is false in meta, return the config as is and do not abort the request
  if (config.meta && config.meta.abortRequests === false) {
    return config;
  }
  config.signal = apiAbortControllers[pathname].signal;
  store.dispatch("showLoadingOverlay", true);
  return config;
});
axios.interceptors.response.use(
  (response) => {
    store.dispatch("showLoadingOverlay", false);
    return response;
  },
  (error) => {
    store.dispatch("showLoadingOverlay", false);
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }

    if (error.response?.status === 401) {
      store.dispatch("user/logoutUser");
      VueCookie.delete("user");
      VueCookie.delete("connect.sid");
      VueCookie.delete("RECRUITER_ACCESS_TOKEN");
      router.push({ name: "401" });
    } else if (error.response?.status === 403) {
      store.dispatch("user/setLastUrlIndex", 2);
      router.push({ name: "403" });
    }
    return Promise.reject(error);
  }
);
router.beforeEach(async (to, from, next) => {
  try {
    const { meta } = to;
    const previousPath = from.path;
    if (apiAbortControllers[previousPath]) {
      apiAbortControllers[previousPath].abort();
      delete apiAbortControllers[previousPath];
    }
    const publicKeys = await store.getters[
      "system_config/publicSystemConfigsObject"
    ];
    if (!publicKeys) {
      await setPublicKeys();
    }
    if (meta) {
      document.title = meta.title;
      switch (meta.user) {
        case "employee": {
          const currentUrl = window.location.pathname;
          const lastURL = localStorage.getItem("lastURL");
          if (!VueCookie.get("user")) {
            localStorage.setItem("lastURL", currentUrl);
            return next("/login?as=employee");
          }
          if (lastURL && currentUrl != lastURL) {
            window.location = lastURL;
          } else {
            localStorage.removeItem("lastURL");
          }
          const user = store.getters["user/loggedInUser"];
          if (!user) {
            setLoggedInUser();
          }
          if (!Object.keys(store.getters["user/permissions"]).length) {
            await setPermissions();
          }
          const permissions = meta.permissions;
          const checkAll = meta.checkAll === true;
          const groupPermissions = meta.groupPermissions === true;
          const allow = meta.allow;
          if (permissions && !allow) {
            const hasPermission = groupPermissions
              ? permissions.map(({ permissions, checkAll = false }) =>
                  hasAccess({ permissions, checkAll }, "cddc")
                )
              : hasAccess({ permissions, checkAll });
            const checkPer = groupPermissions
              ? checkAll
                ? !hasPermission.includes(false)
                : hasPermission.includes(true)
              : hasPermission;
            if (!checkPer) {
              store.dispatch("user/setLastUrlIndex", 1);
              addSnackbarMessage(null, "Permission not allowed!");
              return next("/403");
            }
          }
          break;
        }
        case "recruiter": {
          addRecruiterModule();
          if (meta.credRequired && !VueCookie.get("RECRUITER_ACCESS_TOKEN")) {
            return next("/login?as=recruiter");
          }
          if (
            meta.credRequired &&
            !store.getters["$_recruiter/getLoggedInUser"]
          ) {
            await setLoggedInRecruiter();
          }
          break;
        }
        case "applicant": {
          if (meta.credRequired && !VueCookie.get("APPLICANT_ACCESS_TOKEN")) {
            return next("/login?as=applicant");
          }
          addApplicantMasterModule();
          if (
            meta.credRequired &&
            !store.getters["$_applicant_master/getLoggedInApplicant"]
          ) {
            await setLoggedInApplicant();
          }
          break;
        }
      }
    }
    next();
  } catch (error) {
    addSnackbarMessage(error, "Routing error!");
    store.dispatch("user/setLastUrlIndex", 1);
    next("/403");
  }
});

export default router;
