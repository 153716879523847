<template>
  <div class="content">
    <div v-if="joiningDetails?.aadhaarNumber">
      <common-header
        id="commonHeader"
        class="mt-5"
        :data="['home', 'manage', 'applicantMaster', 'singleApplicant']"
        :componentName="valueForCommonHeader"
      >
      </common-header>
      <div class="col-md-12">
        <div class="d-flex justify-content-between mb-4">
          <div></div>
          <h3>Joining Form Details</h3>
          <button
            class="btn btn-cp float-right"
            @click="createZipWithDocuments"
          >
            Download ZIP
          </button>
        </div>
        <div class="col-sm-12 col-12 pr-sm-2 mb-3">
          <div class="my-2 text-center">
            <img
              v-if="joiningProfilePic"
              :src="joiningProfilePic"
              alt="Profile Picture"
              class="mx-auto d-block rounded-circle"
              style="width: 200px; height: 200px; object-fit: cover"
            />
          </div>
        </div>
        <div class="well">
          <table
            class="table table-condensed table-compressed"
            aria-label="Education Details"
            aria-hidden="true"
          >
            <tbody>
              <tr>
                <td class="col-md-2"><strong>First Name</strong></td>
                <td class="col-md-4">{{ joiningDetails.first_name }}</td>
                <td class="col-md-2"><strong>Last Name</strong></td>
                <td class="col-md-4">{{ joiningDetails.last_name }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Father's Name</strong></td>
                <td class="col-md-4">{{ joiningDetails.fatherName }}</td>
                <td class="col-md-2"><strong>Mother's Name</strong></td>
                <td class="col-md-4">{{ joiningDetails.motherName }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Email</strong></td>
                <td class="col-md-4">{{ joiningDetails.email }}</td>
                <td class="col-md-2"><strong>Secondary Email</strong></td>
                <td class="col-md-4">{{ joiningDetails.secondary_email }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Mobile Number</strong></td>
                <td class="col-md-4">{{ joiningDetails.mobile_no }}</td>
                <td class="col-md-2"><strong>Date of Birth</strong></td>
                <td class="col-md-4">{{ joiningDetails.dob }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>Name As Per Aadhaar</strong></td>
                <td class="col-md-4">{{ joiningDetails.nameAsPerAadhaar }}</td>
                <td class="col-md-2"><strong>Aadhaar Number</strong></td>
                <td class="col-md-4">{{ joiningDetails.aadhaarNumber }}</td>
              </tr>
              <tr>
                <td class="col-md-2"><strong>PAN Number</strong></td>
                <td class="col-md-4">{{ joiningDetails.panNumber }}</td>
                <template v-if="joiningDetails.uanNumber">
                  <td class="col-md-2"><strong>UAN Number</strong></td>
                  <td class="col-md-4">{{ joiningDetails.uanNumber }}</td>
                </template>
                <template v-else>
                  <td class="col-md-2"></td>
                  <td class="col-md-4"></td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12">
        <h3><strong>Uploaded Documents</strong></h3>

        <div class="documents-container">
          <div
            v-for="(doc, index) in joiningDetails.documents"
            :key="index"
            class="document-card"
          >
            <document-preview
              :title="doc.type"
              :id="doc.type"
              :documentUrl="doc.url"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!joiningDetails?.aadhaarNumber && !isLoading"
      class="centered-container"
    >
      <h4 class="d-flex align-items-center justify-content-center">
        Joining Data is not available
      </h4>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import DocumentPreview from "../../ApplicantMaster/_components/document-upload-preview.vue";

export default {
  data() {
    return {
      joiningDetails: null,
      joiningProfilePic: "",
      isLoading: true,
    };
  },
  props: {},
  components: {
    DocumentPreview,
  },
  methods: {
    async fetchJoiningDetails(id) {
      try {
        this.isLoading = true;
        const response = await axios.get(`applicants/joining-details/${id}`);
        this.joiningDetails = response.data;
        this.fetchProfilePic();
      } catch (error) {
        console.error(
          "Error fetching joining details:",
          error.response?.data || error.message
        );
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDocumentAsBlob(url) {
      try {
        const response = await axios.get(url, { responseType: "blob" });
        return new Blob([response.data], { type: "application/pdf" });
      } catch (error) {
        console.error("Error fetching document:", error);
        return null;
      }
    },
    async fetchProfilePic() {
      try {
        // Construct the URL to fetch the image from the backend
        const fileId = this.joiningDetails?.documents?.passportPhoto?.url;
        if (fileId) {
          const url = `/applicants/joining/documents/view/passportPhoto?FileId=${fileId}`;

          const response = await axios.get(url, { responseType: "blob" });

          // Create a Blob URL for the image
          const blobUrl = window.URL.createObjectURL(response.data);

          // Store the Blob URL in the component state
          this.joiningProfilePic = blobUrl;
        }
      } catch (error) {
        console.error("Error fetching the profile picture:", error);
      }
    },

    async createZipWithDocuments() {
      if (
        !this.joiningDetails ||
        !this.joiningDetails.documents ||
        !this.joiningDetails.first_name ||
        !this.joiningDetails.last_name
      ) {
        console.error("Joining details or documents are not available.");
        return;
      }
      const zip = new JSZip();
      const fullName = `${this.joiningDetails.first_name}_${this.joiningDetails.last_name}`;
      // Iterate over all documents
      for (const key in this.joiningDetails.documents) {
        const document = this.joiningDetails.documents[key];
        try {
          const fileBlob = await this.fetchDocumentAsBlob(
            `/applicants/joining/documents/view/${document.type}?FileId=${document.url}`
          );

          if (fileBlob) {
            // Add the file to the ZIP
            if (document.type === "passportPhoto") {
              zip.file(`${document.type}.jpg`, fileBlob);
            } else {
              zip.file(`${document.type}.pdf`, fileBlob);
            }
          }
        } catch (error) {
          console.error(`Failed to fetch ${document.type}:`, error);
        }
      }

      // Generate and save the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${fullName}_Documents.zip`);
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.fetchJoiningDetails(id);
  },
  created() {},
  computed: {
    valueForCommonHeader() {
      const firstName = this.joiningDetails.first_name || "";
      const lastName = this.joiningDetails.last_name || "";
      const fullName = `${firstName} ${lastName}`.trim();
      return fullName || "NA";
    },
  },
};
</script>

<style scoped>
.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 10px;
}

.document-card {
  flex: 1 1 calc(25% - 16px);
  max-width: calc(25% - 16px);
  box-sizing: border-box;
}

/* Large screens (1024px to 1199px) */
@media (max-width: 1199px) {
  .document-card {
    flex: 1 1 calc(33.33% - 16px); /* 3 cards per row */
    max-width: calc(33.33% - 16px);
  }
}

/* Medium screens (768px to 1023px) */
@media (max-width: 1023px) {
  .document-card {
    flex: 1 1 calc(50% - 16px); /* 2 cards per row */
    max-width: calc(50% - 16px);
  }
}

/* Small screens (481px to 767px) */
@media (max-width: 767px) {
  .document-card {
    flex: 1 1 100%; /* 1 card per row */
    max-width: 100%;
  }
}

/* Mobile screens (480px and below) */
@media (max-width: 480px) {
  .document-card {
    flex: 1 1 100%; /* 1 card per row */
    max-width: 100%;
  }
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Full viewport height */
  text-align: center; /* Optional for text alignment */
}

h4 {
  margin: 0; /* Ensures no default margin affects centering */
}
</style>
