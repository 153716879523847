/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get logged in applicant
 */
const getLoggedInApplicant = (state) => state.loggedInApplicant;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get logged in applicant
 */
const getResumeLink = (state) => state.resumeLink;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get logged in applicant
 */
const getProfilePic = (state) => state.profilePic;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get application
 */
const getApplication = (state) => state.application;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get applicant applications
 */
const getApplicantApplications = (state) => state.applications;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get can apply jobs
 */
const getCanApplyJobs = (state) => state.canApplyJobs;

const getApplicantResume = (state) => state.loggedInApplicant.resume;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get applicant link requests
 */
const getApplicantLinkRequests = (state) => state.linkRequests;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description return data of Job application submission status
 */
const formSubmitStatus = (state) => state.formSubmitStatus;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get applicant's 10th marksheet
 */
const getSscMarksheetLink = (state) => state.sscMarksheetLink;

/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get applicant's 12th marksheet
 */

const getHsMarksheetLink = (state) => state.hsMarksheetLink;
/**
 * @memberof module:ApplicantMaster.store.getters
 * @param {Object} state
 * @description get applicant's joining Data
 */

const getJoiningData = (state) => state.joiningData;

/**
 * @memberof module:ApplicantMaster
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  getLoggedInApplicant,
  getApplicantApplications,
  getApplicantLinkRequests,
  getApplicantResume,
  getResumeLink,
  formSubmitStatus,
  getSscMarksheetLink,
  getHsMarksheetLink,
  getCanApplyJobs,
  getApplication,
  getProfilePic,
  getJoiningData,
};
