<template>
  <div
    class="modal fade"
    id="selectCourse"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Select Mail Template</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label class="control-label">Select Mail Template</label>
              </div>
              <select
                class="custom-select main college mr-2"
                v-model="mail_template"
              >
                <option disabled value="">Select Mail Template</option>
                <option
                  v-for="(mailTemplate, index) in filteredMailTemplates"
                  :value="mailTemplate.id"
                  :key="index"
                >
                  {{ mailTemplate.name }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <label
            class="checkbox-inline"
            style="margin-top: 5px; margin-left: 16px; font-size: large"
          >
            <input
              style="margin-top: 5px"
              type="checkbox"
              value="true"
              v-model="sendInvitation"
              checked="true"
            />
            Send Mail
          </label>
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
            @click="$emit('canceled')"
          >
            <strong>Cancel</strong>
          </button>
          <button
            :disabled="!mail_template"
            type="button"
            class="btn btn-cp ml-2"
            @click="submit"
          >
            <i class="fa fa-book fa-lg" aria-hidden="true"></i>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  data() {
    return {
      mail_template: null,
      sendInvitation: true,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      mailTemplates: "$_mailbox/getTemplates",
    }),
    filteredMailTemplates() {
      if (this.mailTemplates) {
        return this.mailTemplates.filter((mail) => {
          return mail.key == "Schedule Round";
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    getAllMailTemplates() {
      this.$store.dispatch("$_mailbox/getTemplates");
    },
    submit() {
      this.$emit("setSelctedMailTemplate", this.mail_template);
      this.$emit("selected", this.sendInvitation);
      this.sendInvitation = true;
      $("#selectCourse").modal("hide");
    },
  },
  mounted() {
    $("#selectCourse").on("shown.bs.modal", () => {
      this.mail_template = null;
      this.getAllMailTemplates();
    });
  },
  beforeDestroy() {
    $("#selectCourse").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.custom-btn-gray {
  height: 35px;
  display: flex;
  align-items: center;
}

/* .modal {
  overflow-y: auto;
} */
</style>
