import axios from "axios";
import applicantAxios from "../_utils/axios";

const prefix = "/applicant-master";

const applicantLogin = (data) => {
  return axios.post(prefix + "/login", data);
};

const applicantRegister = (data) => {
  return axios.post(prefix + "/register", data);
};

const applicantForgotPasswordRequest = (data) => {
  return axios.post(prefix + "/forgot-password-request", data);
};
const checkExpiry = (value) => {
  return axios.get(prefix + "/password/check-status/" + value);
};

const applicantForgotPassword = (data) => {
  return axios.post(prefix + "/forgot-password", data);
};

const applicantEmailVerification = (token) => {
  return axios.get(prefix + `/verify-email/${token}`);
};

const uploadApplicantResume = (data) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key] instanceof File) formData.append(key, data[key]);
    else formData.append(key, String(data[key]));
  }
  return applicantAxios.patch(prefix + "/update-resume", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const uploadApplicantProfilePic = (data) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key] instanceof File) formData.append(key, data[key]);
    else formData.append(key, String(data[key]));
  }
  return applicantAxios.patch(prefix + "/update-profile-pic", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const uploadJoiningDocForm = (data, type) => {
  return applicantAxios.patch(`${prefix}/joining-form-upload/${type}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getJoiningData = () => {
  return applicantAxios.get(prefix + "/joining-form/details");
};
const deleteApplicantProfilePic = () => {
  return applicantAxios.patch(prefix + "/delete-profile-pic");
};

const deleteApplicantResume = () => {
  return applicantAxios.patch(prefix + "/delete-resume");
};

const getApplicantResume = (slug = "me") => {
  return applicantAxios.get(prefix + "/get-resume/" + slug, {
    responseType: "blob",
  });
};

const getApplicantProfile = () => {
  return applicantAxios.get(prefix + "/my-profile");
};

const updateApplicantProfile = (data) => {
  return applicantAxios.patch(prefix + "/my-profile", data);
};

const getApplicantApplications = () => {
  return applicantAxios.get(prefix + "/my-applications");
};

const postLinkRequest = (data) => {
  return applicantAxios.post(prefix + "/application-link-request", data);
};

const getApplicationBySlug = (slug) => {
  return applicantAxios.get(prefix + "/get-application/" + slug);
};

const getAllApplicantLinkRequests = () => {
  return applicantAxios.get(prefix + "/my-link-requests");
};

const postUserdata = (postUserdata) => {
  let formData = new FormData();

  formData.append("resume", postUserdata["resume"]);
  formData.append("profile_picture", postUserdata["profile_picture"]);
  let userData = JSON.stringify(postUserdata);
  formData.append("data", userData);
  return applicantAxios.post("applicants/applicant", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const postUserProject = (data) => {
  return applicantAxios.post(prefix + "/my-profile/project-details", data);
};
const updateUserProject = (data) => {
  return applicantAxios.patch(prefix + "/my-profile/project-details", data);
};
const deleteUserProject = (id) => {
  return applicantAxios.delete(prefix + `/my-profile/project-details/${id}`);
};

const addUserJob = (data) => {
  return applicantAxios.post(prefix + "/my-profile/job-details", data);
};
const updateUserJob = (data) => {
  return applicantAxios.patch(prefix + "/my-profile/job-details", data);
};
const deleteUserJob = (id) => {
  return applicantAxios.delete(prefix + `/my-profile/job-details/${id}`);
};

const addUserCourse = (data) => {
  return applicantAxios.post(prefix + "/my-profile/course-details", data);
};
const updateUserCourse = (data) => {
  return applicantAxios.patch(prefix + "/my-profile/course-details", data);
};
const deleteUserCourse = (id) => {
  return applicantAxios.delete(prefix + `/my-profile/course-details/${id}`);
};

// uploading of applicant's 10th marksheet
const uploadApplicantSscMarksheet = (data) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key] instanceof File) formData.append(key, data[key]);
    else formData.append(key, String(data[key]));
  }

  return applicantAxios.patch(prefix + "/upload-ssc", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

// fetch applicant's 10th marksheet
const getApplicantSscMarksheet = () => {
  return applicantAxios.get(prefix + "/get-ssc-marksheet", {
    responseType: "blob",
  });
};

const getApplicantProfilePic = () => {
  return applicantAxios.get(prefix + "/documents/view/profile_pic");
};

// delete applicant's 10th marksheet
const deleteApplicantSscMarksheet = () => {
  return applicantAxios.patch(prefix + "/delete-ssc");
};

// uploading applicant's 12th marksheet
const uploadApplicantHsMarksheet = (data) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key] instanceof File) formData.append(key, data[key]);
    else formData.append(key, String(data[key]));
  }
  return applicantAxios.patch(prefix + "/upload-hs", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

// fetch applicant's 12th marksheet
const getApplicantHsMarksheet = () => {
  return applicantAxios.get(prefix + "/get-hs-marksheet", {
    responseType: "blob",
  });
};

// deleting applicant's 12th marksheet
const deleteApplicantHsMarksheet = () => {
  return applicantAxios.patch(prefix + "/delete-hs");
};

/**
 * @memberof module:ApplicantMaster.api
 * @memberof module:ApplicantMaster
 * @namespace api
 * @description applicant api for backend interactions
 */
export default {
  applicantLogin,
  applicantRegister,
  getApplicantProfile,
  updateApplicantProfile,
  getApplicantApplications,
  getAllApplicantLinkRequests,
  postLinkRequest,
  uploadApplicantResume,
  getApplicantResume,
  uploadApplicantProfilePic,
  deleteApplicantProfilePic,
  deleteApplicantResume,
  postUserdata,
  applicantEmailVerification,
  applicantForgotPasswordRequest,
  applicantForgotPassword,
  postUserProject,
  updateUserProject,
  deleteUserProject,
  addUserJob,
  updateUserJob,
  deleteUserJob,
  addUserCourse,
  updateUserCourse,
  deleteUserCourse,
  uploadApplicantSscMarksheet,
  getApplicantSscMarksheet,
  deleteApplicantSscMarksheet,
  uploadApplicantHsMarksheet,
  getApplicantHsMarksheet,
  deleteApplicantHsMarksheet,
  getApplicationBySlug,
  getApplicantProfilePic,
  checkExpiry,
  uploadJoiningDocForm,
  getJoiningData,
};
